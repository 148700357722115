import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { Modal3 } from 'components';
import { AddIcon, ArrowDownloadIcon, Button } from '@trustsecurenow/components-library';
import reporting from '../../../helpers/apis/reporting';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const WorkPlanAddButton = ({ tab, label, setRefresh, id, refetchOnCreate }) => {
  const [loading, setLoading] = useState(false);
  const app = 'myCompany';
  const [open, dispatchOpen] = useState(false);

  const setOpen = value => {
    setRefresh(value);
    dispatchOpen(value);
  };

  useEffect(() => {
    // the refresh on the table it was not working on creating a new file
    setRefresh(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function downloadApendix() {
    setLoading(true);
    bsnclientservices
      .getSRAppendixReport(id)
      .then(response => {
        window.open(response.data.url);
        setLoading(false);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err.response);
        setLoading(false);
      });
  }

  return (
    <>
      <Button startIcon={<AddIcon />} onClick={() => setOpen(true)} sx={{ mr: 1, width: 190, whiteSpace: 'nowrap' }}>
        {label}
      </Button>
      {tab === 'sraDocuments' && (
        <Button
          startIcon={<ArrowDownloadIcon />}
          onClick={() => downloadApendix(id)}
          sx={{ mr: 1, width: 230 }}
          loading={loading}
        >
          {loading ? 'Loading...' : 'Download SRA Appendix'}
        </Button>
      )}
      {open && (
        <Modal3
          open={open}
          setOpen={setOpen}
          app={app}
          tab={tab}
          createNew
          TransitionComponent={Transition}
          refetchOnCreate={refetchOnCreate}
        />
      )}
    </>
  );
};

WorkPlanAddButton.propTypes = {
  tab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  refetchOnCreate: PropTypes.func.isRequired
};

export default memo(WorkPlanAddButton);
