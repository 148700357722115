import React from 'react';
import { Container } from 'components';
import cognito from 'helpers/apis/cognito';
import { enqueueAlertSnackbar, Button } from '@trustsecurenow/components-library';
import { usePreassessmentContext } from '../../contexts';
import { PreassessmentInfoContainer, PreassessmentEmailStepTitle } from '../../style';

const PreassessmentAllSet = () => {
  const {
    // dispatch,
    // client_id,
    gotoQuizPage,
    disabled,
    userState,
    user: { first_name, last_name, job_title_id },
    quiz_id
  } = usePreassessmentContext();


  const handleSubmit = async () => {
    if (disabled) return;

    await cognito
      .updateExternalUser({
        first_name,
        last_name,
        job_title_id: job_title_id.toString(),
        quiz_id
      })
      .then(res => {
        if (res.status === 200) gotoQuizPage();
      })
      .catch(err => {
        if (err.response?.data?.code === 400) {
          enqueueAlertSnackbar('You have already taken this assessment', { props: { severity: 'error' } });
        }
        console.log('err', err);
        console.log('err.response', err.response);
      });
  };

  return (
    <>
      <PreassessmentInfoContainer>
        <PreassessmentInfoContainer>
          <Container.Grid sm={12}>
            <PreassessmentEmailStepTitle>All set! You're ready to get started!</PreassessmentEmailStepTitle>
          </Container.Grid>

          <Container.Grid mt={3} sm={12}>
            <Button disabled={disabled} onClick={handleSubmit} color="success">
              {userState === 'start' ? 'Begin Assessment' : 'Continue Assessment'}
            </Button>
          </Container.Grid>
        </PreassessmentInfoContainer>
      </PreassessmentInfoContainer>
    </>
  );
};

export default PreassessmentAllSet;
