import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, DragZone, DateField, SelectField } from 'components';
import * as dashboard from 'helpers/apis/services/dashboard';
import { dataProvider, isValidEmail } from 'helpers';
import { useId, useLocation } from 'hooks';
import MuiPhoneNumber from 'material-ui-phone-number';
import useHasInternet from '../../hooks/useHasInternet';
import useForm from '../../hooks/useForm';
import { resolve } from '../../utils/resolve';
import { enqueueAlertSnackbar, Button } from '@trustsecurenow/components-library';

const initialForm = {
  business_name: '',
  contact: '',
  email: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip_code: '',
  phone: '',
  contract_date: new Date().toISOString().slice(0, 10),
  relationship_id: '',
  other_relationship: '',
  file: undefined
};

const PageDocumentsServiceProviderModal = ({
  record,
  tab,
  type: modalType,
  dispatch,
  id,
  refetchOnCreate,
  ...props
}) => {
  const app = 'clients';
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const { form, onInput } = useForm(initialForm);
  const [relationshipOptions, setRelationshipOptions] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const hasInternet = useHasInternet();
  const isOtherRelationshipSelected =
    form.relationship_id === 'VFZSSlBRPT0=' || form.relationship_id === 'VFhwSlBRPT0=';

  // TODO- Global Api Redux following (Single Source of Truth)
  // TODO- DISPATCH(GetALLReleationShipOtiops(setId));
  // TODO- relationshipOptions= USESELECTOR(root=>  root.relationships.data);
  useEffect(() => {
    // get all relationship options
    if (relationshipOptions === null) {
      dashboard
        .getDocumentsServiceProviderRelationships({
          app: 'clients',
          id: setId
        })
        .then(res => hasInternet(() => setRelationshipOptions(Array.isArray(res.data) ? res.data : [])));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipOptions]);

  const optionsRelationship =
    relationshipOptions &&
    relationshipOptions.map((v, l) => {
      return { value: v.id, label: v.name };
    });

  const handleEmailValidation = e => {
    onInput(e);
    setDisabled(false);
    setIsEmailValid(true);
    setIsEmailEmpty(false);
    if (!isValidEmail(e.target.value)) {
      setIsEmailValid(false);
    }
  };

  // onKeyDown event is used because when adding only space in input[type=email],
  // the onChange event is not triggered
  const handleEmailKeyDown = e => {
    if ((e.key === ' ' || e.code === 'Space' || e.keyCode === 32) && !e.target.value.trim()) {
      setIsEmailEmpty(true);
    } else {
      setIsEmailEmpty(false);
    }
  };

  const saveAll = async function() {
    setDisabled(true);

    const data = { ...form };
    delete data.file;

    if (!isEmailValid) {
      enqueueAlertSnackbar('Please enter a valid email address', { props: { severity: 'error' } });
      return;
    }

    // Show individual error messages for fields
    dispatch.onCheckValidity();

    // Validation on all object if empty some of them are empty

    const requiredFields = [
      data.business_name.trim(),
      data.contact.trim(),
      data.email.trim(),
      data.contract_date,
      data.relationship_id
    ];

    if (requiredFields.some(v => !v)) {
      setDisabled(false);
      enqueueAlertSnackbar('Please enter all required fields', { props: { severity: 'error' } });
      return;
    }

    if (!form.file) {
      setDisabled(false);
      enqueueAlertSnackbar('Please attach a file', { props: { severity: 'error' } });
      return;
    }

    if (form.phone === '+0') {
      setDisabled(false);
      enqueueAlertSnackbar('Please enter a valid phone number', { props: { severity: 'error' } });
      return;
    }

    setIsSaving(true);

    // Add User
    const { response: dataResponse, err: dataErr } = await resolve(() =>
      dashboard.createDocument({
        app,
        documentType: 'documentsServiceProvider',
        id: setId,
        data
      })
    );
    if (dataErr) {
      setDisabled(false);
      setIsSaving(false);
      enqueueAlertSnackbar(dataErr.response?.data?.status, { props: { severity: 'error' } });
      return;
    }

    // preUpload document
    const { response: uploadResponse, err: uploadErr } = await resolve(() =>
      dashboard.presignedUpload({
        app,
        id: dataResponse.data.data.id,
        data: {
          filename: form.file.name,
          type: modalType
        }
      })
    );

    if (uploadErr) {
      setDisabled(false);
      setIsSaving(false);
      enqueueAlertSnackbar(uploadErr.message, { props: { severity: 'error' } });
      return;
    }

    const formData = new FormData();
    formData.append('AWSAccessKeyId', uploadResponse.data.fields.AWSAccessKeyId);
    formData.append('key', uploadResponse.data.fields.key);
    formData.append('policy', uploadResponse.data.fields.policy);
    formData.append('signature', uploadResponse.data.fields.signature);
    formData.append('x-amz-security-token', uploadResponse.data.fields['x-amz-security-token']);
    formData.append('file', form.file);

    // upload document
    const { response: fileUploadResponse, err: fileUploadErr } = await resolve(() =>
      dataProvider.postUrl(uploadResponse.data.url, formData, 'multipart/form-data')
    );

    if (fileUploadErr) {
      console.error(fileUploadErr);
      setDisabled(false);
      setIsSaving(false);
      return;
    }

    enqueueAlertSnackbar('Success', { props: { severity: 'success' } });
    setDisabled(false);
    setIsSaving(false);
    dispatch.onClose();
    refetchOnCreate && refetchOnCreate();
  };

  const handleChange = (...params) => {
    setDisabled(false);
    onInput(...params);
  };

  return (
    <Container.Grid direction="column">
      <Container.Grid item alignItems="center" sm={24} xs={24} xl={24} md={24}>
        <Container.Grid direction="column">
          <Container.Grid spacing={4}>
            <input type="hidden" name="id" value={id} />
            <Container.Grid item sm={4} xs={12}>
              <TextField
                value={form.business_name}
                name="business_name"
                label={tab === 'documentsServiceProvider' ? 'Service Provider Organization Name' : 'Business Name'}
                onChange={handleChange}
                multiline
                fullWidth
                required
                error={form.business_name.trim().length === 0 && Boolean(form.business_name)}
                helperText={
                  form.business_name.trim().length === 0 && Boolean(form.business_name) && 'Please enter a valid name'
                }
              />
            </Container.Grid>
            <Container.Grid item sm={4} xs={12}>
              <TextField
                value={form.contact}
                name="contact"
                label="Contact Name"
                onChange={handleChange}
                multiline
                fullWidth
                required
                error={form.contact.trim().length === 0 && Boolean(form.contact)}
                helperText={form.contact.trim().length === 0 && Boolean(form.contact) && 'Please enter a valid name'}
              />
            </Container.Grid>
            <Container.Grid item sm={4} xs={12}>
              <TextField
                type="email"
                value={form.email}
                name="email"
                label="Email Address"
                onChange={e => handleEmailValidation(e)}
                onKeyDown={e => handleEmailKeyDown(e)}
                fullWidth
                required
                error={!isEmailValid || isEmailEmpty}
                helperText={(!isEmailValid || isEmailEmpty) && 'Please enter a valid email address'}
                inputLabelProps={{
                  ...(isEmailEmpty && { shrink: true })
                }}
              />
            </Container.Grid>
          </Container.Grid>
          <Container.Grid spacing={4}>
            <Container.Grid item sm={4} xs={12}>
              <TextField
                value={form.address}
                name="address"
                label="Address"
                onChange={handleChange}
                multiline
                fullWidth
              />
            </Container.Grid>
            <Container.Grid item sm={4} xs={12}>
              <TextField
                value={form.address2}
                name="address2"
                label="Address 2"
                onChange={handleChange}
                multiline
                fullWidth
              />
            </Container.Grid>
            <Container.Grid item sm={4} xs={12}>
              <TextField value={form.city} name="city" label="City" onChange={handleChange} multiline fullWidth />
            </Container.Grid>
          </Container.Grid>
          <Container.Grid spacing={4}>
            <Container.Grid item sm={4} xs={12}>
              <TextField value={form.state} name="state" label="State" onChange={handleChange} multiline fullWidth />
            </Container.Grid>
            <Container.Grid item sm={4} xs={12}>
              <TextField
                value={form.zip_code}
                name="zip_code"
                label="Zip Code"
                onChange={handleChange}
                integerOnly
                inputProps={{ maxLength: 5 }}
                fullWidth
              />
            </Container.Grid>
            <Container.Grid item sm={4} xs={12} mt={1}>
              <MuiPhoneNumber
                variant="outlined"
                value={form.phone}
                name="phone"
                label="Phone Number"
                onChange={value => {
                  handleChange({ currentTarget: { name: 'phone', value } });
                }}
                defaultCountry="us"
                fullWidth
              />
            </Container.Grid>
          </Container.Grid>
          <Container.Grid spacing={4} mt={0.2}>
            <Container.Grid item sm={4} xs={12}>
              <DateField
                id="contract_date"
                datePicker
                label="Contract Date"
                name="contract_date"
                onChange={value => {
                  handleChange({ currentTarget: { name: 'contract_date', value } });
                }}
                value={form.contract_date}
                fullWidth
                required
              />
            </Container.Grid>
          </Container.Grid>
          <Container.Grid spacing={4} mt={0.2}>
            {optionsRelationship && (
              <>
                <Container.Grid item mt={2} sm={12} xs={12}>
                  <SelectField
                    name="relationship_id"
                    value={form.relationship_id}
                    emptyValue="Select Please"
                    label="Relationship"
                    fullWidth
                    onChange={e => handleChange({ currentTarget: { name: e.target.name, value: e.target.value } })}
                    choices={optionsRelationship}
                    required
                  />
                </Container.Grid>
                {isOtherRelationshipSelected && (
                  <Container.Grid item sm={12} xs={12}>
                    <TextField
                      value={form.other_relationship}
                      name="other_relationship"
                      label="Other Relationship"
                      multiline
                      rows={2}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Container.Grid>
                )}
              </>
            )}
            <DragZone
              title="Upload a file*"
              fileType=".doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls , .jpeg, .tiff, .png, .jpg"
              size={12}
              record={form}
              dispatch={file => {
                onInput({ currentTarget: { name: 'file', value: file } });
              }}
              type="file"
            />
          </Container.Grid>
        </Container.Grid>
      </Container.Grid>
      <Container.Grid item justify="flex-end" alignItems="center" mt={2} sm={12} xs={12} xl={12} md={12}>
        <Button sx={{ ml: 2 }} onClick={() => saveAll()} disabled={disabled || isSaving}>
          Save
        </Button>
      </Container.Grid>
    </Container.Grid>
  );
};

PageDocumentsServiceProviderModal.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    business_name: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    contract_date: PropTypes.string.isRequired,
    relationship_id: PropTypes.string.isRequired,
    other_relationship: PropTypes.string.isRequired
  }).isRequired,
  tab: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dispatch: PropTypes.shape({
    onClose: PropTypes.func.isRequired,
    presignedUpload: PropTypes.func.isRequired,
    onCheckValidity: PropTypes.func.isRequired
  }).isRequired,
  refetchOnCreate: PropTypes.func.isRequired
};

export default PageDocumentsServiceProviderModal;
