import { buildQueryString, decodeId } from 'helpers/utils';
import { getStorage } from 'helpers/storage';
import { dataParams } from 'helpers';
import apiClient from '../../apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';

const BASE_PATH = `${DOMAIN_URL}/BSNPartnersAPI`;

export const getPresignedPost = (mimeType, partner_Id, type = '', serviceLogoMimeType = '') => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/getPreSignedPost/${partner_Id}?${type}`,
    params: { mimeType, serviceLogoMimeType },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const migrateToEnterprise = (partner_Id, bpp_tier_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/EBP/migrate/${partner_Id}`,
    headers: getHeaders(),
    data: { bpp_tier_id }
  };
  return apiClient.post(requestOptions);
};

export const getSraAutoPublishSettings = partner_id => {
  const requestOptions = {
    url: `${BASE_PATH}/partnerProfile/auto-publish-settings/${partner_id}/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setSraAutoPublishSettings = (partner_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/partnerProfile/auto-publish-settings/${partner_id}/`,
    headers: getHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

export const audiLogsSearch = (search, filters = {}) => {
  const queryParams = buildQueryString(filters);
  const requestOptions = {
    url: `${BASE_PATH}/logs/search/${search}${queryParams}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const audiLogs = (filters = {}) => {
  const queryParams = buildQueryString(filters);
  const requestOptions = {
    url: `${BASE_PATH}/logs${queryParams}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const exportLogs = (filters = {}) => {
  const queryParams = buildQueryString(filters);
  const requestOptions = {
    url: `${BASE_PATH}/logs/export${queryParams}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const submitTicket = payload => {
  const requestOptions = {
    url: `${BASE_PATH}/createAuditLogTicket`,
    headers: getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

export const getQuicksightDashboards = () => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboards`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getQuicksightDashboard = dashboardId => {
  const requestOptions = {
    url: `${BASE_PATH}/${dashboardId}/dashboard`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getProgress = () => {
  const userId = decodeId(getStorage('userId'));
  const requestOptions = {
    url: `${BASE_PATH}/setupGuide/progress/${userId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const welcomeMessageStatus = () => {
  const userId = decodeId(getStorage('userId'));
  const requestOptions = {
    url: `${BASE_PATH}/setupGuide/welcomeMessageStatus/${userId}`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const disableNotification = () => {
  const requestOptions = {
    url: `${BASE_PATH}/setupGuide/disableNotification`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const updatePartnerProfile = (partnerId, fileName) => {
  const requestOptions = {
    url: `${BASE_PATH}/partnerProfile/customProfile/${partnerId}`,
    headers: getHeaders(),
    data: fileName
  };
  return apiClient.put(requestOptions);
};

export const updatePartnerProfileServiceLogo = (partnerId, serviceLogo) => {
  const requestOptions = {
    url: `${BASE_PATH}/partnerProfile/customProfile/${partnerId}`,
    headers: getHeaders(),
    data: serviceLogo 
  };
  return apiClient.put(requestOptions);
};

export const getPartnersList = params => {
  const requestOptions = {
    url: `${BASE_PATH}/partnerslist`,
    headers: getHeaders(),
    params: dataParams(params) 
  };
  return apiClient.get(requestOptions);
};

export const deletePartnerInformation = id => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/information/${id}`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const getPartnerInformation = partnerId => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/information/${partnerId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const updatePartnerInformation = (partnerId, record) => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/information/${partnerId}`,
    headers: getHeaders(),
    data: record
  };
  return apiClient.put(requestOptions);
};

export const createPartner = record => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/addPartner`,
    headers: getHeaders(),
    data: record
  };
  return apiClient.post(requestOptions);
};

export const getSimilarPartners = name => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/checkSamePartners`,
    headers: getHeaders(),
    params: { name }
  };
  return apiClient.get(requestOptions);
};

export const getTmsOptions = () => {
  const requestOptions = {
    url: `${BASE_PATH}/partner/tmsinformation`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getPolicesList = params => {
  const requestOptions = {
    url: `${BASE_PATH}/policieslist`,
    headers: getHeaders(),
    params: dataParams(params)
  };
  return apiClient.get(requestOptions);
};

export const getClientNotesList = (params, id) => {
  const newparams = { ...dataParams(params), partner: 0 };
  const requestOptions = {
    url: `${BASE_PATH}/note/${id}`,
    headers: getHeaders(),
    params: newparams
  };
  return apiClient.get(requestOptions);
};

export const getPartnerNotesList = (params, id) => {
  const newparams = dataParams({ ...params, partner: 1 });
  const requestOptions = {
    url: `${BASE_PATH}/note/${id}`,
    headers: getHeaders(),
    params: newparams
  };
  return apiClient.get(requestOptions);
};

export const getLogsBody = (logId) => {
  const requestOptions = {
    url: `${BASE_PATH}/logs/body/${logId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};