// @flow
import React, { type ComponentType,useState } from 'react';
import styled from 'styled-components';
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { LazyIcon } from 'components/icons';
import { Link, Tooltip } from '@material-ui/core';
import { hasKey, strClean, dataProvider } from 'helpers';
import reporting from '../../helpers/apis/reporting';
import TableListAction from './TableListAction';
import EditingCell from './EditingCell';
import UpdatingCell from './UpdatingCell';
import { BadgeIcon, ButtonTag, ActionButton } from './ComponentTypes';
import { ButtonLink } from '../../apps/dashboard/ComponentTypes';
import { DateTime } from '../text';
import useHasInternet from '../../hooks/useHasInternet';
import contentAdmin from 'helpers/apis/contentAdmin';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const ContainerPolices: ComponentType<*> = styled.div`
  width: 65%;
  display: flex;
  justify-content: space-between;

  & button {
    padding: 0;
    min-width: inherit;

    & > span {
      width: inherit;
    }
  }
`;

const Email: ComponentType<*> = styled.p`
  word-break: break-word;
  min-width: 170px;
  max-width: 300px;
  margin: 0;
`;

const typeCell = (value, statePass, { button = false, buttonLabel = '', id }) => {

  if (typeof value === 'object' && value !== null) {
    if (typeof value.$$typeof === 'symbol') return value;
    return value[statePass];
  }

  if (value === 'yellow' || value === 'red' || value === 'green') return <BadgeIcon color={value} />;

  if (button) {
    return (
      <ButtonLink size="small" href={value}>
        <VisibilityOutlinedIcon />
        &nbsp;&nbsp; {buttonLabel}
      </ButtonLink>
    );
  }

  if(id && id === 'email') return <Email>{value}</Email>;

  return value;
};

type TypesCellTypes = {
  cell: any,
  rowId: string,
  row: Object,
  paramIdForModal: string,
  hideIcon: boolean,
  statePass: any,
  config: {
    button?: boolean,
    buttonLabel?: string,
    editing: boolean,
    filter: boolean,
    id: string,
    label?: string,
    iconLabel?: string,
    renderCheckIcon?: boolean,
    renderViewIcon?: boolean,
    renderActiveIcon?: boolean,
    renderOnlyActiveIcon?: boolean,
    renderPrintIcon?: boolean,
    renderEmailIcon?: boolean,
    renderEditTag?: boolean,
    updating?: {
      app?: string,
      tab?: string,
      field?: string
    },
    slice?: number,
    accessLevel?: boolean,
    tag?: boolean,
    policesTooltip?: string,
    action?: {
      icon?: string | null,
      label?: string | null
    },
    type?: 'date'
  }
};

const TypesCell = ({ cell, paramIdForModal, row, hideIcon, statePass, config, rowId }: TypesCellTypes) => {
  const [isDisabled, setIsDisabled]=useState(false);

  const hasInternet = useHasInternet();

  if (hasKey(config, 'action'))
    return <TableListAction hideIcon={hideIcon} paramId={paramIdForModal} {...config.action} rowId={rowId} />;

  const [val] = cell;
  if (hasKey(config, 'renderCheckIcon')) return val ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
  if (hasKey(config, 'renderViewIcon') && hasKey(config, 'renderEditIcon')) {
    return (
      <>
        <Link href={val} target="_blank" rel="noreferrer">
          <VisibilityOutlinedIcon />
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link href={val} target="_blank" rel="noreferrer">
          <EditIcon />
        </Link>
      </>
    );
  }
  if (hasKey(config, 'removeHtml')) return cell[0] && strClean(cell[0]);
  if (hasKey(config, 'renderViewIcon')) {
    return (
      <Link href={val} target="_blank" rel="noreferrer">
        <VisibilityOutlinedIcon />
        &nbsp;&nbsp; {config.iconLabel}
      </Link>
    );
  }
  if (hasKey(config, 'renderEditIcon')) {
    return (
      <Link href={val} target="_blank" rel="noreferrer">
        <EditIcon />
        &nbsp;&nbsp; {config.iconLabel}
      </Link>
    );
  }
  if (hasKey(config, 'renderDowloadIcon')) {
    if(val){
      return (
        <Link href={val} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
          <LazyIcon color="colorDarkGrey" size="fontSize" component="Download" />
          {config.iconLabel && <>&nbsp;&nbsp;{config.iconLabel}</>}
        </Link>
      );
    }
    return <div />
  }
  if (hasKey(config, 'printWorkPlanReport')) {
    const callWorkPlanReport = () =>{ 
      bsnclientservices
        .getWorkplanReport(row.client_id, { year: row.year })
        .then(res =>{
          window.open(res.data.url)
          setIsDisabled(false)  
        })
        .catch(error => {
          enqueueAlertSnackbar(`${error.response}`, { props: { severity: 'error' } });
        setIsDisabled(false) 
      });
      }
    return (
      <ActionButton
        onClick={e => {
          setIsDisabled(true);
          e.stopPropagation();
          callWorkPlanReport();
        }}
        disabled={isDisabled}
      >
        <LazyIcon color="colorDarkGrey" size="fontSize" component="Print" />
        {config.iconLabel && <>&nbsp;&nbsp;{config.iconLabel}</>}
      </ActionButton>
    );
  }
  if (hasKey(config, 'printerCampaignReport')) {
    const handleCampaignReport = e => {
      e.stopPropagation();
      contentAdmin
        .getSingleCampaignPhisihingReport(row.id)
        .then(res => window.open(res.data.url))
        .catch(error => enqueueAlertSnackbar(`${error.response}`, { props: { severity: 'error' } }));
    };

    return (
      <>
        {row.status === 'Ended' && (
          <ActionButton onClick={e => config.id === 'filename' && handleCampaignReport(e)}>
            <LazyIcon size="fontSize" color="colorDarkGrey" component="Print" />
            {config.iconLabel && <>&nbsp;&nbsp;{config.iconLabel}</>}
          </ActionButton>
        )}
      </>
    );
  }

  if (hasKey(config, 'slice')) {
    if (cell[0] !== null && cell[0].length > config.slice) return `${cell[0].slice(0, config.slice)}...`;
    return cell[0];
  }

  if (hasKey(config, 'tag') && cell[0]) {
    if (typeof cell[0] === 'object') {
      return (
        <Tooltip title={cell[0].tolltip} arrow>
          <ButtonLink>{cell[0].label}</ButtonLink>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={cell[0]} arrow>
        <ButtonTag>{cell[0].substr(0, 10)}</ButtonTag>
      </Tooltip>
    );
  }

  if (hasKey(config, 'policesTooltip')) {
    return (
      <ContainerPolices>
        <div>
          {cell[0].acknowledged_policies_at && (
            <Tooltip title="Policies" arrow>
              <ButtonLink>
                <LazyIcon component="Insurance" color="colorDefault" />
              </ButtonLink>
            </Tooltip>
          )}
        </div>
        <div>
          {cell[0].acknowledged_other_policies_at && (
            <Tooltip title="Other Policies" arrow>
              <ButtonLink>
                <LazyIcon component="Insurance" color="colorDefault" />
              </ButtonLink>
            </Tooltip>
          )}
        </div>
      </ContainerPolices>
    );
  }

  if (hasKey(config, 'accessLevel')) {
    const c = cell[0];
    if (!hasKey(c, 'marketing_material')) return '';
    const accessOrder: {
      order1: boolean,
      order2: boolean,
      order3: boolean
    } = {
      order1: c.marketing_material,
      order2: c.purchasing,
      order3: c.billing
    };

    return Object.entries(accessOrder).map(icon => {
      if (icon[1])
        return (
          <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" ml={1.1} mr={1.1} />
        );
      return <LazyIcon component="Disable" color="colorDefault" ml={1.1} mr={1.1} />;
    });
  }

  if (hasKey(config, 'renderActiveIcon')) {
    if (cell[0])
      return (
        <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" ml={1.5} mr={1.5} />
      );
    return <LazyIcon component="Disable" color="colorDefault" ml={1.5} mr={1.5} />;
  }

  if (hasKey(config, 'renderOnlyActiveIcon')) {
    if (cell[0])
      return (
        <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" ml={1.5} mr={1.5} />
      );
    return <div />;
  }

  if (hasKey(config, 'renderEmailIcon')) {
    if (cell[0]) return <LazyIcon color="colorSystemDanger" component="WelcomeMessage" size={2} ml={2.5} />;
  }

  if (hasKey(config, 'updating')) {
    return <UpdatingCell cellId={rowId} cellValue={cell[0]} cellConfig={config.updating} />;
  }

  if (config?.type === 'date') {
    return <DateTime date={cell[0]} />;
  }

  return (
    <>
      {cell[1] ? (
        <EditingCell cellValue={cell[0]} cellEditing={cell[2][0]} cellEditType={cell[1][1]} />
      ) : (
        typeCell(cell[0] ?? '', statePass, config)
      )}
    </>
  );
};

export default TypesCell;
