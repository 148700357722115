import { TRAINING_TRAININGS_USER } from './constants';
import { useQuery } from 'react-query';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';
import { useCallback, useState } from 'react';
import { useClientId } from 'helpers';
import { useId, useLocation } from '../../../hooks';

const useTrainingModal = ({id, source, mainTableFilterString}) => {

  const [params, setParams] = useState(null)
  const { item } = useLocation(); // get client id from the route
  const client_id = useId({ key: 'clientId' }); // get client id from the user logged in
  const clientId = item || client_id;
  const trainingId = TRAINING_TRAININGS_USER === source ? `${clientId}/${id}` : id

  const { isLoading, data, error, isFetching} = useQuery(
    ["get training modal data", params],
    async () => bsnclientservices.trainingModal(source, trainingId, params),
    {
      staleTime: 1000000,
      cacheTime: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!params
    }
  );

  const onLoadParams = useCallback(({sortname, order, page, perPage, ...params}) => {
    setParams({
      sort: { sortname, order },
      pagination: { page, perPage },
      _filter: mainTableFilterString,
      ...params
    })
  }, [])

  const onChangeSort = useCallback((sort) => {setParams(prev => ({ ...prev, sort, pagination: {...prev.pagination, page: 0} }))}, [])
  const onChangePage = useCallback((page) => {setParams(prev => ({ ...prev, pagination: {...prev.pagination, page} }))}, [])
  const onChangePerPage = useCallback((perPage) => {setParams(prev => ({ ...prev, pagination: {...prev.pagination, perPage, page: 0} }))}, [])
  const onSearch = useCallback((value) => setParams(prev => ({...prev, _filter: `name:${value}`})) )

  return {
    loading: isLoading,
    data: data?.data,
    count: +data?.headers?.["x-total-count"],
    error,
    isFetching,
    dispatch: {
      onLoadParams,
      onChangeSort,
      onChangePage,
      onChangePerPage,
      onSearch
    }
  }
}

export default useTrainingModal
