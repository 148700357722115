import { Box, DataGrid, Paper, useGridApiRef } from '@trustsecurenow/components-library';
import { DeleteConfirmationModal } from 'components';
import * as sra from 'helpers/apis/services/sra';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import generateApiTableParams from 'utils/generateApiTableParams';
import { useSRA } from '../context/sraContext';
import Toolbar from './Toolbar';
import StatusCell from './StatusCell';
import ReportFilesCell from './ReportFilesCell';
import ActionsCell from './ActionsCell';
import DateCell from './DateCell';
import NoRowsOverlay from './NoRowsOverlay';
import WorkPlan from '../../workPlan/WorkPlan';
import { useQuery } from 'react-query';
import { dataProvider, getSessionStorage, getStorage } from 'helpers';

const SRA_REPORTS_TABLE_NAME = 'SRAReports';

const ReportsTable = React.memo(({ getTaskList, hasLegacyDocuments, isMarkedComplete }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentReportId, setCurrentReportId] = useState('');
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [rowCount, setRowCount] = useState(0);
  const [isPreferenceFetched, setIsPreferenceFetched] = useState(false);
  const [legacyDocuments, setLegacyDocuments] = useState(false);

  const { clientId } = useSRA();
  const apiRef = useGridApiRef();

  const fetchReports = useCallback(async () => {
    if (!isPreferenceFetched) return;
    try {
      setIsLoading(true);
      const params = generateApiTableParams({ pagination: paginationModel });
      userPreferences.setMuiDataGridSettings(SRA_REPORTS_TABLE_NAME, { pageSize: paginationModel.pageSize });
      const response = await sra.getSraReports(params, clientId);
      setRows(response.data.result);
      setRowCount(response.data.count);
    } finally {
      setIsLoading(false);
    }
  }, [clientId, isPreferenceFetched, paginationModel]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  useEffect(() => {
    setIsPreferenceFetched(true);
    const preferences = userPreferences.getMuiDataGridSettings(SRA_REPORTS_TABLE_NAME);
    if (preferences)
      setPaginationModel(prevState => ({ ...prevState, pageSize: preferences.pageSize || prevState.pageSize }));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!apiRef.current || isLoading) return;
      if (apiRef.current.autosizeColumns) {
        apiRef.current.autosizeColumns({ columns: ['report_name'], includeOutliers: true, includeHeaders: true });
      }
    }, 0);
  }, [apiRef, isLoading]);

  const refetch = useCallback(() => {
    fetchReports();
    // force refresh of task list to prevent stale data being displayed in (e.g. date completed in MarkComplete card)
    getTaskList();
  }, [fetchReports, getTaskList]);


  // get partner_id
  const { partner_id } = useSelector(s => s?.bsn?.user?.profile ?? {});
  // switch partner id in case of mask mode
  const maskMode = getSessionStorage('maskMode');
  const maskPartnerId = getSessionStorage('maskPartnerId')
  const partnerId = maskMode ? maskPartnerId : partner_id;

  const {
    data: clientsListInfo,
    isLoading: isLoadingClientsListInfo,
  } = useQuery({
    queryKey: [
      'dataProvider.getOne',
      'clients',
      `list/${partnerId}`,
      { _filter: `id:${String(clientId)}` }
    ],
    queryFn: () => dataProvider.getOne(
      'clients',
      `list/${partnerId}`,
      { _filter: `id:${String(clientId)}` }
    ),
    enabled: Boolean(clientId && partnerId)
  })
  const {
    data: clientInfo,
    isLoading: isLoadingClientInfo,
  } = useQuery({
    queryKey: [
      'sraApi.getClientInfo',
      clientId,
    ],
    queryFn: () => sra.getClientInfo(clientId),
    enabled: Boolean(clientId)
  })

  const isInternal = Boolean(clientInfo?.data?.is_internal)
  const branding = clientsListInfo?.data?.clients?.[0]?.branding

  const columns = useMemo(
    () => [
      { field: 'report_name', headerName: 'Report Name', sortable: false, minWidth: 280 },
      {
        field: 'date_completed',
        headerName: 'Date',
        sortable: false,
        renderCell: props => <DateCell {...props} refetch={refetch} />,
        width: 156
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        renderCell: StatusCell,
        width: 150
      },
      {
        field: 'report_files',
        headerName: 'Report Files',
        sortable: false,
        renderCell: ReportFilesCell,
        width: 558
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        renderCell: props => <ActionsCell
          {...props}
          branding={branding}
          isInternal={isInternal}
          refetch={fetchReports}
          onDeleteReport={onDeleteReport}
          getTaskList={getTaskList}
          isMarkedComplete={isMarkedComplete}
          page={paginationModel.page}
        />,
        width: 420
      }
    ],
    [fetchReports, branding, isInternal, paginationModel.page]
  );

  useEffect(() => {
    sessionStorage.removeItem('accessEditSRA');
    sessionStorage.removeItem('accessWorkplanSRA');
  }, []);

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentReportId('');
    setTimeout(() => {
      setDeleteSuccess(false);
    }, 500);
  };

  const deleteReport = () => {
    setDeleteLoading(true);
    sra
      .deleteSraReports(clientId, currentReportId)
      .then(res => {
        setDeleteSuccess(true);
        fetchReports();
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const onDeleteReport = id => {
    setCurrentReportId(id);
    setOpenDeleteModal(true);
  };

  return (
    <Paper component={Box} mt={3.5}>
      <Toolbar
        hasLegacyDocuments={hasLegacyDocuments}
        legacyDocuments={legacyDocuments}
        onLegacyDocumentsChange={setLegacyDocuments}
      />

      {legacyDocuments ? (
        <WorkPlan app="myCompany" isLegacyDocuments />
      ) : (
        <DataGrid
          apiRef={apiRef}
          slots={{ noRowsOverlay: NoRowsOverlay }}
          columns={columns}
          rows={rows}
          hideFooter={!rows.length}
          autoHeight
          disableColumnReorder
          disableColumnFilter
          disableColumnResize
          rowHeight={60}
          columnHeaderHeight={60}
          loading={isLoading || isLoadingClientInfo || isLoadingClientsListInfo}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={rowCount}
          onPaginationModelChange={setPaginationModel}
          disableColumnMenu
        />
      )}

      <DeleteConfirmationModal
        open={openDeleteModal}
        close={closeDeleteModal}
        onDelete={deleteReport}
        showSuccessDialog={deleteSuccess}
        successMessage="Reports Deleted!"
        title="Delete Reports?"
        description="Are you sure you want to delete these Reports?"
        additionalText={false}
        disabledSubmit={deleteLoading}
      />
    </Paper>
  );
});

export default ReportsTable;
