import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';
import { bsnClientServicesBaseURL } from './bsnClientServices';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const baseURL = bsnClientServicesBaseURL;
const userAddApi = process.env.REACT_APP_BASE_URL_20;
const userAddBaseURL = `https://${userAddApi}.${base}/${env}`;
const baseURLUser = bsnClientServicesBaseURL;

const clients = {};
const DEFAULT_CONTENT_TYPE = 'application/json';

clients.getRequestHeaders = (includeAuthorization = false, contentType = DEFAULT_CONTENT_TYPE) => {
  return {
    'Content-Type': contentType,
    ...(includeAuthorization ? { Authorization: getStorage('idToken', true) } : {})
  };
};

clients.addClient = (client_id, data, params) => {
  // https://v11mklrp1c.execute-api.us-east-1.amazonaws.com/dev/clients/add/VFdjOVBRPT0=?_start=0&_end=25&_sort=name&_order=ASC&timestamp_cache=1667469051176
  const requestOptions = {
    url: `${userAddBaseURL}/clients/add/${client_id}`,
    data,
    headers: clients.getRequestHeaders(true),
    params
  };
  return apiClient.post(requestOptions);
};

export default clients;
