// @flow
import { getStorage, isMaskModeEnabled, getSessionStorage } from 'helpers';
import useLocation from './useLocation';

const idMap = {
  myDashboard: {
    default: 'userId'
  },
  myCompany: {
    default: 'clientId'
  },
  phishing: {
    default: 'clientId'
  },
  clients: {
    list: 'partnerId',
    default: 'clientId'
  },
  partnerProfile: {
    default: 'partnerId'
  },
  user: {
    default: '',
    profile: 'userId'
  },
  newsfeed: {
    default: 'clientId'
  },
  contentadmin: {
    default: 'clientId'
  },
  getStarted: {
    default: 'userId'
  },
  admin: {
    default: 'clientId'
  }
};

type useIdTypes = {
  key?: 'partnerId' | 'userId' | 'clientId' | false,
  app?: string | false,
  tab?: string | false
};

function useId({ key = false, app = false, tab = false }: useIdTypes = { key: false, app: false, tab: false }): string {
  const { app: appLoc } = useLocation();
  let newKey = idMap[appLoc]?.default;
  if (app) {
    const m = idMap[app.split('/')[0]];
    newKey = m?.tab || m?.default;
  }
  if (key) {
    const isMaskMode = isMaskModeEnabled(app || appLoc);
    newKey = (key === 'partnerId' && isMaskMode) ? 'maskPartnerId' : key;
  }

  // Returned values
  if (newKey === 'maskPartnerId')
    return getSessionStorage(newKey)
  else
    return getStorage(newKey, true);
}

export default useId;
