import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getActiveTab, setStorage, useApp, setSessionStorage, removeSessionStorage } from 'helpers';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const useMaskPartner = () => {
  const { dispatch } = useApp();
  const history = useHistory();

  const {
    tabs,
    system: { tabCurrent }
  } = useSelector(rxState => rxState.bsn);

  const enableMaskMode = (data, filters) => {
    setSessionStorage('maskMode', true);
    setSessionStorage('maskPartnerId', data.id);
    setSessionStorage('maskPartnerName', data.name);
    setSessionStorage('maskPartnerDistributor', data.distributor);
    // Key to show loading after enable or disable mask partner
    setStorage('showLoading', true, true);

    let path = '/clients'
    dispatch.changeApp('clients', 'admin', getActiveTab(tabs['clients']), tabCurrent);

    if (filters.user && data.client_id) {
      const filter = encodeURIComponent(JSON.stringify({ user: filters.user }))
      path = `/clients/users/${data.client_id}?filter=${filter}`
    }
    
    if (filters.client && data.client_name) {
      const filter = encodeURIComponent(JSON.stringify({ name: data.client_name }))
      path = `/clients?filter=${filter}`
    }

    history.push(path);
  };

  const cleanMaskModeStorage = () => {
    removeSessionStorage('maskMode')
    removeSessionStorage('maskPartnerId');
    removeSessionStorage('maskPartnerName');
    removeSessionStorage('maskPartnerDistributor');
    // Key to show loading after enable or disable mask partner
    setStorage('showLoading', true, true);
    enqueueAlertSnackbar('Mask mode disabled');
  };

  const returnToAdmin = () => {
    dispatch.changeApp('admin', 'clients', 'dashboard', tabCurrent);
    cleanMaskModeStorage();
    history.push('/admin/dashboard/manage-partners');
  };

  return {
    enableMaskMode,
    cleanMaskModeStorage,
    returnToAdmin
  };
};

export default useMaskPartner;