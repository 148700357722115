import { useEffect, useState } from 'react';
import * as bsnpartnersapi from 'helpers/apis/services/bsnpartnersapi';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

export const usePartnerChecklist = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [aboutVideo, setAboutVideo] = useState('');
  const [tasks, setTasks] = useState({});

  const dispatch = {};

  dispatch.disableNotification = () => {
    bsnpartnersapi
      .disableNotification()
      .then(res => {})
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    bsnpartnersapi
      .getProgress()
      .then(res => {
        const taskList = res.data.products.tasks;
        const { total_done, total_tasks } = taskList;
        const progressVal = ((total_done / total_tasks) * 100).toFixed(0);
        setProgress(+progressVal);
        setAboutVideo(res.data.learn_more.video_url);
        setTasks(taskList);
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { loading, progress, aboutVideo, tasks, dispatch };
};
