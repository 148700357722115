import { buildQueryString } from 'helpers/utils';
import dataParams from 'helpers/dataParams';
import apiClient from '../../apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';

const BASE_PATH = `${DOMAIN_URL}/bsnclientservices`;

export const getClientsList = async (partnerId, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/list/${partnerId}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const activateDeactivateClient = async (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/actions/${clientId}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};
 
export const handleClientBulkAction = async (partnerId, { ...props }) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/${partnerId}`,
    headers: getHeaders(),
    ...props
  };
  return apiClient.post(requestOptions);
};

export const deleteClient = async clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/actions/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const enableDisableWelcomeMessage = async (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/sendAutomated/${clientId}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const manageClientActions = (id, data, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/${id}`,
    data,
    headers: getHeaders(),
    params
  };
  return apiClient.post(requestOptions);
};

export const deleteBulkClient = (partnerId, data, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/${partnerId}`,
    data,
    headers: getHeaders(),
    params
  };
  return apiClient.delete(requestOptions);
};

export const applyClientAction = (clientId, action, systemEmailsEnabled) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/actions/${clientId}`,
    headers: getHeaders(),
    data: {
      type: action,
      system_emails_status: systemEmailsEnabled
    }
  };
  return apiClient.post(requestOptions);
};

export const getMTNLData = (id, ...props) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/mt-newsletter-engagement-status/${id}`,
    headers: getHeaders(),
    ...props
  };
  return apiClient.get(requestOptions);
};

export const enableNewsLetters = (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/options/${clientId}`,
    headers: getHeaders(),
    data
  };
  return apiClient.patch(requestOptions);
};

export const getWelcomeMsgInfo = (id, ...props) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/directorySync/${id}`,
    headers: getHeaders(),
    ...props
  };
  return apiClient.get(requestOptions);
};

export const sendTeamsAppAnnouncement = (clientId, usersList = null) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/teams-app/notification/${clientId}`,
    headers: getHeaders()
  };
  if (usersList !== null) {
    requestOptions.data = { selected_users: usersList };
  }
  return apiClient.post(requestOptions);
};

export const getTeamsAppUnregisteredUsers = clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/teams-app/unregistered-users/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getClientInfo = async clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/${clientId}`,
    headers: getHeaders()
  };
  const { data: clientInfo } = await apiClient.get(requestOptions);
  return clientInfo;
};

export const getPresignedPost = (mimeType, clientId) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/getPreSignedPost/${clientId}`,
    params: { mimeType },
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const updateClientAccess = (clientId, accessData) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/access/${clientId}`,
    data: accessData,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const getInformation = (clientId, section) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/${section}/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const putInformation = (clientId, section, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/${section}/${clientId}`,
    data,
    headers: getHeaders()
  };
  return apiClient.patch(requestOptions);
};

export const getInformationSettings = clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/settings/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const deleteInformationLogo = clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/logo/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const getPickList = params => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/pickList`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const setIndustryToClient = (client_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/${client_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

export const testCustomMessage = (client_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/testcustomMessage/${client_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const getClientCutomMessages = (client_id, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/customMessage/${client_id}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const getClientReport = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/client_report`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getESSReport = (client_id, tags) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/ess_report${tags?.length ? '?tags='.concat(tags) : ''}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getAssignedTrainingsReport = ({ clientId, selected }) => {
  const params = {};
  if (selected?.length) {
    params.user_ids = `${selected.join(',')}`;
  }
  const queryParams = new URLSearchParams(params).toString();
  const requestOptions = {
    url: `${BASE_PATH}/${clientId}/assigned_training_report${queryParams.length ? `?${queryParams}` : ''}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getSelectedTrainingReport = ({ clientId, trainingId, selected }) => {
  const params = {};
  if (trainingId) {
    params.training_id = trainingId;
  }
  if (selected?.length) {
    params.user_ids = `${selected.join(',')}`;
  }
  const queryParams = new URLSearchParams(params).toString();
  const requestOptions = {
    url: `${BASE_PATH}/${clientId}/selected_training_report${queryParams.length ? `?${queryParams}` : ''}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getWorkplanReport = (workplan_id, params = {}) => {
  const requestOptions = {
    url: `${BASE_PATH}/workplan_report/${workplan_id}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const getLastWorkplan = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/workplan-info/${client_id}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getInterviewQuestions = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/interview_questions`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getOrgProfileCSV = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/organization_profile`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getSraAnswers = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/sra_answers`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setClientCustomMessage = (client_id, params, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/customMessage/${client_id}`,
    headers: getHeaders(),
    params,
    data
  };
  return apiClient.post(requestOptions);
};

export const revertCustomMessage = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/customMessage/revert/${client_id}`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

/**
 * gets operation buttons status
 * @param clientId : client id
 * @returns buttons status
 */
export const getOperationButtonsStatus = async clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/usersOperations/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getSRAppendixReport = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/sra_appendix`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getSraAnnualLimit = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/sra-retakes/${client_id}/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setSraAnnualLimit = (client_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/sra-retakes/${client_id}/`,
    headers: getHeaders(),
    data
  };
  return apiClient.patch(requestOptions);
};

export const getWelcomeMessageVideo = partner_id => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/welcome-msg-video/${partner_id}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const aadAuth = data => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/dmd-auth/`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const getDMDState = clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/dmd/${clientId}/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const deleteDMDState = clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/dmd/${clientId}/`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const getPartnerCutomMessages = (partner_id, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/partnerProfile/customMessages/${partner_id}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

// saving welcome message
export const setPartnerCustomMessage = (partner_id, params, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/partnerProfile/customMessages/${partner_id}`,
    headers: getHeaders(),
    data,
    params
  };
  return apiClient.post(requestOptions);
};

export const getUrl = clientId => {
  return apiClient.get({
    url: `${BASE_PATH}/${clientId}/preassessments/`,
    headers: getHeaders()
  });
};

export const fetchAssessmentData = (clientId, type) => {
  return apiClient.get({
    url: `${BASE_PATH}/${clientId}/preassessments/?type=${type}`,
    headers: getHeaders()
  });
};

export const detectAssessment = (preassessmentId, clientId) => {
  return apiClient.get({
    url: `${BASE_PATH}/preassessments/${preassessmentId}/?client_id=${clientId}`,
    headers: getHeaders()
  });
};

export const getUsers = (clientId, queryParams, cancelToken) => {
  const queryString = buildQueryString(queryParams)
  return apiClient.get({
    url: `${BASE_PATH}/${clientId}/quiz-users/${queryString}`,
    headers: getHeaders(),
    cancelToken
  });
};

export const updateBaselineAssessmentEmployeeCount = (clientId, data) => {
  return apiClient.put({
    url: `${BASE_PATH}/${clientId}/preassessments/?type=baseline`,
    headers: getHeaders(),
    data
  });
};

export const trainingFilters = id => {
  const requestOptions = {
    url: `${BASE_PATH}/myCompany/trainingFilter/${id}`,
    headers: getHeaders(),
  };
  return apiClient.get(requestOptions);
}

export const trainingReport = (id, reportType, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/${reportType}/${id}`,
    headers: getHeaders(),
    params: dataParams(params),
  };
  return apiClient.get(requestOptions);
}

export const trainingModal = (trainingType, id, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/${trainingType}/${id}`,
    headers: getHeaders(),
    params: dataParams(params),
  };
  return apiClient.get(requestOptions);
}