// @flow

const storageType: Function = (type: boolean = false): typeof sessionStorage | typeof localStorage => localStorage;

export const setStorage: Function = (key: string, data: string | number | boolean, toSession?: boolean): void => {
  storageType(toSession).setItem(key, data);
};

export const getStorage: Function = (key: string, fromSession?: boolean): string | null | typeof undefined => {
  return storageType(fromSession).getItem(key);
};

export const clearStorage: Function = (): void => {
  localStorage.clear();
};

export const removeStorage: Function = (key: string, fromSession?: boolean): string | null | typeof undefined => {
  return storageType(fromSession).removeItem(key);
};

export const getStorageObject = (key, storage = 'localStorage') => {
  const storedValue = storage === 'localStorage' ? localStorage.getItem(key) : sessionStorage.getItem(key);
  return storedValue !== null ? JSON.parse(storedValue) : null;
};

export const setStorageObject = (key, value, storage = 'localStorage') => {
  storage === 'localStorage' ? localStorage.setItem(key, JSON.stringify(value)) : sessionStorage.setItem(key, JSON.stringify(value));
};

// SESSION STORAGE

export const setSessionStorage: Function = (key: string, data: string | number | boolean): void => {
  sessionStorage.setItem(key, data);
};

export const getSessionStorage: Function = (key: string): string | null | typeof undefined => {
  return sessionStorage.getItem(key);
};

export const clearSessionStorage: Function = (): void => {
  sessionStorage.clear();
};

export const removeSessionStorage: Function = (key: string): string | null | typeof undefined => {
  return sessionStorage.removeItem(key);
};