// @flow

import React, { type ComponentType, type Element, useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'hooks';
import {
  useApp,
  getActiveTab,
  getActiveAvailableTab,
  hasKey,
  decodeId,
  setUnopenedNotificationsCount,
  authProvider,
  trackingUtils,
  dataProvider,
  getSessionStorage
} from 'helpers';
import { transitions, BSN_SET_USER_ALL_NOTIFICATIONS_SEEN, mobileBlockedPages } from 'conf';
import Logo from '../../common/Logo';
import { LazyIcon, Arrow, HelpCenterIcon } from 'components/icons';
import { layoutMenuKit, layoutToolbarBottomData } from '../helper';
import MenuProfileInfo from './MenuProfileInfo';
import MenuFavorite from './MenuFavorite';
import { useTabsAllowed } from 'hooks';
import { Accordion, AccordionDetails, AccordionSummary, Hidden, Typography } from '@material-ui/core';
import { CustomTooltip } from 'components';
import ReferralCode from './ReferralCode/ReferralCode';
import { isSupported } from 'firebase/messaging';
import { getStorage } from 'helpers';
import useHasInternet from 'hooks/useHasInternet';
import isEqual from 'lodash/isEqual';
import bsnClientServices from 'helpers/apis/bsnClientServices';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { MenuIcon, ListItemIcon, MenuItem } from '@trustsecurenow/components-library';
import { RESOURCES } from './constans';
import { Link } from 'react-router-dom';

const checkNotificationPermission = async () => {
  if ('Notification' in window) {
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  }
  return false;
};

const getFirebaseRequestPermissionListener = async () => {
  const isFirebaseSupported = await isSupported();
  if (isFirebaseSupported) {
    const module = await import('firebaseInit');
    return module.onRequestPermissionListener;
  }
  return null;
};

export const MainMenu: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
  background: var(--colorBaseBase);
  overflow: hidden;

  /* TODO: Change CSS Variables */
  transition: all all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};
  /* position: relative; */

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0 36px;
    overflow-y: auto;
    border-radius: 0 5px 5px 0;
  }
`;

export const MainMenuLogoContainer: ComponentType<*> = styled.div`
  margin-top: calc(var(--spacing) * 2);
  margin-bottom: calc(var(--spacing) * 2);
  text-align: center;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.breakpoints.down('sm')} {
    border-radius: 5px;
    overflow: hidden;
    display: flex;
  }
`;

const MenuItemLinkSingleStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  a {
    width: 100%;
    min-height: 48px;
    font-weight: ${({ active }) => (active ? 700 : 500)};
    padding: 0 16px !important;
    background-color: ${({ active }) => (active ? 'var(--colorBaseDark1)' : 'var(--colorBaseBase)')} !important;

    &.has-notification {
      position: relative;
      padding-right: 30px !important;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        display: inline-block;
        background: #ff5a61;
        border-radius: 50%;
        top: 50%;
        margin-top: -5px;
        right: 17px;
      }
    }

    & > div {
      margin-right: 3px;
    }
  }

  .menu-item-icon {
    color: #fff;
  }
`;

export const MainMenuItem: ComponentType<*> = styled.div``;

export const MenuItemLink: ComponentType<*> = styled(MenuItem)`
  &.has-notification {
    position: relative;
    padding-right: 30px;

    &:after {
      ${({ notifnumber }) => notifnumber && `content: '${notifnumber > 9 ? '9+' : notifnumber}';`}
      position: absolute;
      width: 18px;
      height: 18px;
      display: inline-block;
      background: #FF5A61;
      border-radius: 50%;
      top: 50%;
      margin-top: -9px;
      right: 40px;
      color: #fff;
      font-size: 10px;
      line-height: 18px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 1px;
    }
  }
  padding-left: 16px;
  && {
    ${({ pt }) => pt && `padding-top: ${pt}px;`}
    ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
    ${({ pl }) => pl && `padding-left: ${pl}px;`}
    ${({ pr }) => pr && `padding-right: ${pr}px;`}
    ${({ mt }) => mt && `margin-top: ${mt}px;`}
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
    ${({ ml }) => ml && `margin-left: ${ml}px;`}
    ${({ mr }) => mr && `margin-right: ${mr}px;`}
    ${({ bt }) => bt && `border-top: ${bt}px solid #ffffff1a;`};


    border-radius:7px;
    line-height: 1.5;


    > div { min-width: inherit; }

    .menu-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      font-size: 19px;
      margin-right: 13px;
      color: var(--colorCommonWhite);
      width: 19px;
      height: 16px;
    }
    background-color: ${({ active, maskMode }) =>
      !active ? 'transparent' : maskMode ? 'var(--colorSystemInfo)' : 'var(--colorBaseDark2)'};
    ${props => props.theme.breakpoints.down('sm')} {
      border-radius: 5px;
      letter-spacing: 0;
      font-size: 14px;

      &.tabs-items {
        font-weight: ${({ active }) => (active ? 700 : 400)};
        padding-left: 35px;
        color: ${({ active }) => (active ? '#fff' : 'var(--colorGreyLight3)')};
      }
    }
  }
`;

const AccordionContent = styled(Accordion)`
  background-color: transparent !important;
  box-shadow: none !important;

  &.Mui-expanded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &:before {
    content: none !important;
  }
`;

const AccordionDetailsContent = styled(AccordionDetails)`
  flex-direction: column;
`;

const MenuArrowIcon = styled(Arrow)`
  width: 12px !important;
  height: 12px !important;
`;

const AccordionSummaryContainer = styled(AccordionSummary)`
  background-color: ${({ active }) => (active ? 'var(--colorBaseDark1)' : 'var(--colorBaseBase)')} !important;
  border-radius: 5px !important;

  &.accordion-summary.Mui-expanded {
    min-height: 48px;
  }

  &.accordion-summary > div:first-child {
    margin: 0 !important;
    position: relative;
  }

  &.accordion-summary > div:last-child {
    transform: rotate(180deg);

    &.Mui-expanded {
      transform: rotate(0deg);
    }
  }
`;

const AccordionSummaryContent = styled.div`
  display: flex;
  align-items: center;

  .menu-item-icon {
    color: #fff;
  }

  h3 {
    color: #ffffff;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 500;
  }
`;

export type MenuResource = {
  hasCreate: boolean,
  hasEdit: boolean,
  hasList: boolean,
  hasShow: boolean,
  icon: Function,
  name: string,
  label: string
};

export type MenuProps = {
  dense: boolean,
  hasDashboard: boolean,
  logout: Element<*>,
  onMenuClick?: Function,
  open: boolean,
  width: string
};

const Menu = ({
  dense,
  hasDashboard,
  onMenuClick,
  logout,
  width,
  setOpenModalSideBar,
  ...rest
}: MenuProps): Element<*> => {
  const dispatchRx = useDispatch();
  const { dispatch } = useApp();
  const { app, tab: currentTab, item: currentItem } = useLocation();
  const userProfile = useSelector(rxState => rxState.bsn.user.profile);
  const newsfeedAccess = useSelector(({ bsn }) => bsn?.user?.access?.apps?.newsfeed);
  const hasNotification = useSelector(state => state.newsfeed.newsfeed_unopened_notifications_count);
  const [favoriteApp, setFavoriteApp] = useState('');
  const [reloadMenu, setReloadMenu] = useState(false);
  const userId = userProfile?.id && decodeId(userProfile.id);
  const userAccess = useSelector(({ bsn }) => bsn?.user?.access, isEqual);
  const adminResources = useSelector(({ admin }) => admin?.resources);
  const {
    tabs,
    user: { profile, access },
    system: { tabCurrent, item, location: locationCurrent }
  } = useSelector(rxState => rxState.bsn);

  const resources = useMemo(
    () => RESOURCES.filter(resource => Boolean(userAccess) && hasKey(userAccess.apps, resource.name)),
    [userAccess]
  );

  const requestFCMPermission = useCallback(async () => {
    const firebaseRequestPermissionListener = await getFirebaseRequestPermissionListener();
    if (!firebaseRequestPermissionListener) return;

    const isPermissionGranted = await checkNotificationPermission();
    if (!isPermissionGranted) return;

    const firebaseToken = await firebaseRequestPermissionListener();
    usersAPI.setDevicesToken(userId, firebaseToken);
  }, [userId]);

  useEffect(() => {
    if (locationCurrent === 'newsfeed') {
      requestFCMPermission();
    }
  }, [requestFCMPermission, locationCurrent]);

  const hasLogo = profile?.logo_partner || false;

  const onClick = (e, location, tab) => {
    trackingUtils.clickApp(location);
    dispatch.changeApp(location, locationCurrent, getActiveTab(tabs[location]), tabCurrent, item);
    typeof onMenuClick === 'function' && onMenuClick(e);
  };

  const onClickMobile = location => {
    trackingUtils.clickApp(location);
    setOpenModalSideBar(false);
    setReloadMenu(prevState => !prevState);
  };

  const onFocusChange = () => {
    if (!document.hidden) {
      usersAPI.getUserData(['feed']).then(resp => {
        dispatchRx(setUnopenedNotificationsCount(true, resp.data.newsfeed_unopened_notifications_count));
        dispatchRx({
          type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
          payload: resp.data.all_newsfeed_notifications_seen
        });
      });
    }
  };

  useEffect(() => {
    if (newsfeedAccess) {
      window.addEventListener('visibilitychange', onFocusChange);
      return () => {
        window.removeEventListener('visibilitychange', onFocusChange);
      };
    }
  }, [newsfeedAccess]);

  useEffect(() => {
    if (userProfile?.newsfeed_unopened_notifications_count) {
      usersAPI.getUserData(['feed']).then(res => {
        dispatchRx(setUnopenedNotificationsCount(true, res.data.newsfeed_unopened_notifications_count));
        dispatchRx({
          type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
          payload: res.data.all_newsfeed_notifications_seen
        });
      });
    }
  }, [userProfile]);

  const onKeyDown = e => {
    if (e.keyCode === 13 || e.keyCode === 32) e.preventDefault();
  };

  useEffect(() => {
    const fav = getStorage('favorite', true);
    setFavoriteApp(fav);
  }, []);

  const desktopMenuItems = useMemo(
    () =>
      resources.map(resource => {
        const { name, hasDot, icon, label } = resource;
        const maskMode = resource.hasMaskMode && getSessionStorage('maskMode');
        const tab = getActiveAvailableTab(userAccess.apps[name], tabs[name]);
        const to = name === 'clients' ? `/${name}` : `/${name}/${tab || ''}`;
        const active = app === name;
        const Icon = icon || MenuIcon;
        const title = label || name;
        return (
          <CustomTooltip
            title={
              <Typography style={{ fontSize: 'var(--fontSize)' }} component="p">
                {resource.tooltip || title}
              </Typography>
            }
            placement="right"
            key={name}
          >
            <div style={{ borderRadius: '7px' }} className="link-wrap">
              <MenuItemLink
                key={name}
                to={to}
                onClick={e => onClick(e, name)}
                data-ga-app="true"
                onKeyDown={onKeyDown}
                dense={dense}
                active={active}
                maskMode={maskMode}
                className={hasDot && hasNotification ? 'has-notification' : ''}
                notifnumber={hasNotification}
                pt={10}
                pb={10}
                pr={30}
                mt={5}
                mb={5}
                component={Link}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <span className="menu-label">{title}</span>
              </MenuItemLink>
              <MenuFavorite name={name} favoriteApp={favoriteApp} setFavoriteApp={setFavoriteApp} right={12} />
            </div>
          </CustomTooltip>
        );
      }),
    [resources, tabs, app, hasNotification, favoriteApp]
  );

  const MobileMenuItemsTabs = ({ currentApp, hasDot }) => {
    const allowedLayout = useTabsAllowed({ layout: tabs[currentApp], app: currentApp, isMobile: true });

    return Object.keys(allowedLayout).map((tab, index) => {
      return (
        <MenuItemLink
          className={'tabs-items'}
          key={index}
          to={({ pathname }) => {
            const [, , , item] = pathname.split('/');
            const isActive = currentTab && currentApp ? currentApp.toLowerCase() === app.toLowerCase() : false;
            return item && isActive ? `/${currentApp}/${tab}/${item}` : `/${currentApp}/${tab}`;
          }}
          onClick={() => {
            setOpenModalSideBar(false);
            setReloadMenu(prevState => !prevState);
            trackingUtils.clickTab(currentApp, tab);
          }}
          data-ga-tab="true"
          dense={dense}
          active={
            currentTab && currentApp
              ? currentTab.toLowerCase() === tab.toLowerCase() && currentApp.toLowerCase() === app.toLowerCase()
              : false
          }
          className={hasDot && hasNotification ? 'has-notification' : ''}
          notifnumber={hasNotification}
          pt={10}
          pb={10}
          mt={5}
          mb={5}
          component={Link}
        >
          {tabs[currentApp][tab].label}
        </MenuItemLink>
      );
    });
  };

  const MenuItemLinkSingle = ({ resource }) => {
    const currentApp = resource.name;
    const isActive =
      currentTab && currentApp
        ? currentTab.toLowerCase() === 'dashboard' && currentApp.toLowerCase() === app.toLowerCase()
        : currentApp.toLowerCase() === 'clients' && app.toLowerCase() === currentApp.toLowerCase()
        ? true
        : false;
    return (
      <MenuItemLinkSingleStyled active={isActive}>
        <MenuItemLink
          to={({ pathname }) => {
            if (currentApp === 'clients') {
              return `/${currentApp}/`;
            }
            const [, , , item] = pathname.split('/');
            return item && currentApp !== 'newsfeed' ? `/${currentApp}/dashboard/${item}` : `/${currentApp}/dashboard`;
          }}
          onClick={() => onClickMobile(currentApp)}
          data-ga-app="true"
          dense={dense}
          active={isActive}
          className={resource.hasDot && hasNotification ? 'has-notification' : ''}
          component={Link}
        >
          <ListItemIcon>
            <resource.icon className="menu-item-icon" />
          </ListItemIcon>
          {resource.label}
        </MenuItemLink>
        <MenuFavorite name={currentApp} favoriteApp={favoriteApp} setFavoriteApp={setFavoriteApp} right={39} />
      </MenuItemLinkSingleStyled>
    );
  };

  const MobileMenuItems = () => {
    const location = useLocation();
    const hasInternet = useHasInternet();
    const resourcesList = resources.filter(r => r.hasList);
    const clientName = getStorage('clientName', true);
    const [company, setCompany] = useState(null);

    useEffect(() => {
      if (location.item && app === 'clients') {
        bsnClientServices
          .getCompanyName({ clientID: item })
          .then(resp => {
            hasInternet(() => {
              if (typeof resp === 'object' && hasKey(resp, 'data'))
                setCompany(resp.data.name ? resp.data : { name: clientName });
            });
          })
          .catch(err => {
            setCompany({ name: clientName });
          });
      }

      return () => {
        setCompany(null);
      };
    }, [location.item]);

    return resources.map(resource => {
      const { name } = resource;

      if (mobileBlockedPages.includes(name)) return null;

      if (name === 'clients' && !location.item) {
        return <MenuItemLinkSingle resource={resource} />;
      }

      if (resource.hasOwnProperty('hasMobileSublist') && !resource.hasMobileSublist)
        return <MenuItemLinkSingle resource={resource} />;

      return (
        <AccordionContent>
          <AccordionSummaryContainer
            expandIcon={<MenuArrowIcon />}
            aria-controls={`${name}-content`}
            id={`${name}-header`}
            className={'accordion-summary'}
            active={app === name}
          >
            <AccordionSummaryContent>
              <resource.icon className={'menu-item-icon'} />
              {name === 'clients' ? (
                <MenuItemLink component={Link} to="/clients" onClick={() => onClickMobile(name)}>
                  {resource.label}
                </MenuItemLink>
              ) : (
                <h3>{resource.label}</h3>
              )}
            </AccordionSummaryContent>
            <MenuFavorite name={name} favoriteApp={favoriteApp} setFavoriteApp={setFavoriteApp} right={0} />
          </AccordionSummaryContainer>
          <AccordionDetailsContent>
            {name === 'clients' &&
              (company?.name ? (
                <h4 style={{ color: 'white', margin: '5px auto' }}>{company.name}</h4>
              ) : (
                <LazyIcon component="Loading" color="colorCommonWhite" mr={1} />
              ))}
            <MobileMenuItemsTabs currentApp={name} hasDot={resource.hasDot} />
          </AccordionDetailsContent>
        </AccordionContent>
      );
    });
  };

  const mobileMenuItems = useMemo(() => <MobileMenuItems />, [
    resources,
    favoriteApp,
    currentItem,
    reloadMenu,
    currentTab,
    app
  ]);

  return (
    <MainMenu {...rest} style={{ minHeight: '-webkit-fill-available', height: '-webkit-fill-available' }}>
      <MainMenuItem>
        {hasLogo && (
          <MainMenuLogoContainer>
            <Logo />
          </MainMenuLogoContainer>
        )}
        <MenuProfileInfo />
        <Hidden smDown>{desktopMenuItems}</Hidden>
        <Hidden mdUp>{tabs && app && app !== 'login' && mobileMenuItems}</Hidden>
      </MainMenuItem>
      <MainMenuItem>
        <ReferralCode />
        <Hidden mdUp>
          <MenuItemLink
            to={layoutToolbarBottomData[1].link}
            dense={dense}
            onClick={() => dispatch.changeTab(app, null, null, item)}
            data-ga-button={layoutToolbarBottomData[1].label}
            pt={20}
            pb={20}
            pl={16}
            pr={16}
            mb={10}
            bt={1}
            component={Link}
          >
            <ListItemIcon>
              <LazyIcon component={layoutToolbarBottomData[1].icon} />
            </ListItemIcon>
            {layoutToolbarBottomData[1].label}
          </MenuItemLink>
        </Hidden>
        <Hidden smDown>
          {profile?.zendesk_enabled &&
            profile?.partner_distributor !== 'MP' && profile?.help_center_url && (
              <MenuItemLink
                to={window.location.hash.substr(1)}
                dense={dense}
                onClick={() => window.open(profile?.help_center_url, '_blank')}
                pt={20}
                pb={20}
                pl={5}
                pr={5}
                mb={5}
                bt={1}
                component={Link}
              >
                <ListItemIcon>
                  <HelpCenterIcon />
                </ListItemIcon>
                {"Help Center"}
              </MenuItemLink>
            )}
          {access &&
            access.features.marketing_material &&
            layoutMenuKit.map(({ icon, label, id, link }) => (
              <MenuItemLink
                component="a"
                href={link}
                target="_blank"
                key={id}
                dense={dense}
                pt={20}
                pb={20}
                pl={5}
                pr={5}
                mb={35}
                bt={!profile?.zendesk_enabled && 1}
              >
                <ListItemIcon>
                  <LazyIcon component={icon} />
                </ListItemIcon>
                {label}
              </MenuItemLink>
            ))}
        </Hidden>
      </MainMenuItem>
    </MainMenu>
  );
};

Menu.defaultProps = {
  onMenuClick: () => null
};

export default Menu;
