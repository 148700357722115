import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, DragZone, DateField, SelectField } from 'components';
import { isValidEmail } from 'helpers';
import { useLocation, useId } from 'hooks';
import * as dashboard from 'helpers/apis/services/dashboard';
import MuiPhoneNumber from 'material-ui-phone-number';
import useHasInternet from '../../hooks/useHasInternet';

const PageDocumentsServiceProviderInfoModal = ({ record, tab, type: modalType, dispatch, id, setDisabled, ...props }) => {
  const [data, setData] = useState(record);
  const hasInternet = useHasInternet();
  const [relationshipOptions, setRelationshipOptions] = useState(null);
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const isOtherRelationshipSelected =
    data.relationship_id === 'VFZSSlBRPT0=' || data.relationship_id === 'VFhwSlBRPT0=';

  const handleEmailValidation = ({ target }) => {
    if (!isValidEmail(target.value)) target.setCustomValidity('Please enter a valid email address');
    else target.setCustomValidity('');

    setData({ ...data, [target.name]: target.value });
  };

  const onChange = ({ target }) => {
    setData({ ...data, [target.name]: target.value });

    if (target.required) {
      if (!target.value.trim()) target.setCustomValidity('Please fill out this field.');
      else target.setCustomValidity('');
    }
  };

  const onValueChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const onFileChange = file => {
    setData({ ...data, file });
    dispatch.fileChanged(file);
  };

  useEffect(() => {
    setDisabled(true);
    if (relationshipOptions === null) {
      dashboard
        .getDocumentsServiceProviderRelationships({
          app: 'clients',
          id: setId
        })
        .then(res => hasInternet(() => { 
          setRelationshipOptions(Array.isArray(res.data) ? res.data : [])
          setDisabled(false);
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipOptions]);

  const optionsRelationship =
    relationshipOptions &&
    relationshipOptions.map((v, l) => {
      return { value: v.id, label: v.name };
    });

  return (
    <>
      <Container.Grid direction="column">
        <Container.Grid spacing={4}>
          <input type="hidden" name="id" value={id} />
          <Container.Grid item sm={4} xs={12}>
            <TextField
              value={data.business_name}
              name="business_name"
              label={'Business Name'}
              onChange={onChange}
              multiline
              fullWidth
              required
            />
          </Container.Grid>
          <Container.Grid item sm={4} xs={12}>
            <TextField
              value={data.contact}
              name="contact"
              label="Contact Name"
              onChange={onChange}
              multiline
              fullWidth
              required
            />
          </Container.Grid>
          <Container.Grid item sm={4} xs={12}>
            <TextField
              type="email"
              value={data.email}
              name="email"
              label="Email Address"
              onChange={handleEmailValidation}
              fullWidth
              required
              inputProps={{ maxlength: 199 }}
            />
          </Container.Grid>
        </Container.Grid>
        <Container.Grid spacing={4}>
          <Container.Grid item sm={4} xs={12}>
            <TextField value={data.address} name="address" label="Address" onChange={onChange} multiline fullWidth />
          </Container.Grid>
          <Container.Grid item sm={4} xs={12}>
            <TextField
              value={data.address2}
              name="address2"
              label="Address 2"
              onChange={onChange}
              multiline
              fullWidth
            />
          </Container.Grid>
          <Container.Grid item sm={4} xs={12}>
            <TextField value={data.city} name="city" label="City" onChange={onChange} multiline fullWidth />
          </Container.Grid>
        </Container.Grid>
        <Container.Grid spacing={4}>
          <Container.Grid item sm={4} xs={12}>
            <TextField value={data.state} name="state" label="State" onChange={onChange} multiline fullWidth />
          </Container.Grid>
          <Container.Grid item sm={4} xs={12}>
            <TextField
              value={data.zip_code}
              name="zip_code"
              label="Zip Code"
              onChange={onChange}
              integerOnly
              inputProps={{ maxLength: 5 }}
              fullWidth
            />
          </Container.Grid>
          <Container.Grid item sm={4} xs={12} mt={1}>
            <MuiPhoneNumber
              variant="outlined"
              value={data.phone}
              name="phone"
              label="Phone Number"
              onChange={e => onValueChange('phone', e)}
              defaultCountry="us"
              fullWidth
            />
          </Container.Grid>
        </Container.Grid>
        <Container.Grid spacing={4}>
          <Container.Grid item mt={1} sm={4} xs={12}>
            <DateField
              id="date"
              datePicker
              label="Contract Date"
              name="contract_date"
              onChange={value => onValueChange('contract_date', value)}
              value={data.contract_date || new Date().toISOString()}
              fullWidth
              required
            />
          </Container.Grid>
          <Container.Grid item mt={1} sm={4} xs={12}>
            <DateField
              id="date"
              datePicker
              label="Created Date"
              name="created"
              disabled
              value={data.created}
              onChange={onChange}
              fullWidth
            />
          </Container.Grid>
          <Container.Grid item mt={1} sm={4} xs={12}>
            <DateField
              id="date"
              datePicker
              label="Modified Date"
              name="modified"
              disabled
              value={data.modified}
              onChange={onChange}
              fullWidth
            />
          </Container.Grid>
        </Container.Grid>
        <Container.Grid spacing={4}>
          {optionsRelationship && (
            <>
              <Container.Grid item mt={2} sm={12} xs={12}>
                <SelectField
                  name="relationship_id"
                  value={data.relationship_id}
                  emptyValue="Select Please"
                  label="Relationship"
                  fullWidth
                  onChange={onChange}
                  choices={optionsRelationship}
                  required
                />
              </Container.Grid>
              {isOtherRelationshipSelected && (
                <Container.Grid item sm={12} xs={12}>
                  <TextField
                    value={data.other_relationship}
                    name="other_relationship"
                    label="Other Relationship"
                    multiline
                    rows={2}
                    onChange={onChange}
                    fullWidth
                  />
                </Container.Grid>
              )}
            </>
          )}
        </Container.Grid>
        <Container.Grid item direction="column" mt={2} pb={2} sm={12} xs={12} xl={12} md={12}>
          <Container.Grid direction="row" alignItems="center" sm={5} xs={5} xl={5} md={5}>
            <DragZone
              title="Upload a file*"
              fileType=".doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls , .jpeg, .tiff, .png, .jpg"
              size={12}
              record={data}
              dispatch={onFileChange}
              type="file"
              disabledClick
            />
          </Container.Grid>
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

PageDocumentsServiceProviderInfoModal.propTypes = {
  record: PropTypes.shape({
    business_name: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    contract_date: null,
    created: null,
    modified: null,
    relationship_id: PropTypes.string.isRequired,
    other_relationship: PropTypes.string.isRequired
  }).isRequired,
  tab: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dispatch: PropTypes.shape({
    onClose: PropTypes.func.isRequired,
    fileChanged: PropTypes.func.isRequired
  }).isRequired,
  id: PropTypes.string.isRequired
};

export default PageDocumentsServiceProviderInfoModal;
