import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilterField from 'apps/auditlog/FilterField';
import * as bsnpartnersapi from 'helpers/apis/services/bsnpartnersapi';
import { enqueueAlertSnackbar, Button } from '@trustsecurenow/components-library';
import { useDebounce } from 'hooks';
import ClearIcon from '@material-ui/icons/Clear';
import { convertDateToUTC, createDateAsUTC } from '../../helpers';
import contentAdmin from 'helpers/apis/contentAdmin';

const searchLimit = 20;
const customZIndex = 1000;
const debounce_delay = 500;

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 202
  }
}));

const Container = styled.div`
  height: ${({ open }) => {
    return open ? 'auto' : '50px';
  }};
  background-color: var(--backgroundDefault);
`;

const TextFieldsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 15px;
  flex-wrap: wrap;
  .applyFiltersBtn {
    margin: 10px auto;
    flex-basis: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const FiltersBar = ({ filters, applyFilters }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [internalFilters, setInternalFilters] = useState({
    ...filters
  });
  const [partners, setPartners] = useState({
    options: [],
    open: false,
    selected: null,
    search: null,
    loading: false
  });

  const [clients, setClients] = useState({
    options: [],
    open: false,
    selected: null,
    search: null,
    loading: false
  });

  const [emailTemplates, setEmailTemplates] = useState({
    options: [],
    open: false,
    selected: null,
    search: null,
    loading: false
  });

  const [searchPartnerDebounce] = useDebounce({ value: partners.search, delay: debounce_delay });
  const [searchClientDebounce] = useDebounce({ value: clients.search, delay: debounce_delay });

  const getPartners = (partnerName = null) => {
    setPartners(prev => ({
      ...prev,
      loading: true
    }));
    bsnpartnersapi
      .audiLogsSearch('partners', { ...(partnerName ? { name: partnerName } : {}), _limit: searchLimit })
      .then(res => {
        setPartners(prev => ({
          ...prev,
          options: res?.data?.data || []
        }));
      })
      .catch(e => {
        enqueueAlertSnackbar('failed to load partners', { props: { severity: 'error' } });
      })
      .finally(() =>
        setPartners(prev => ({
          ...prev,
          loading: false
        }))
      );
  };

  const getClients = (partnerId, clientName = null) => {
    setClients(prev => ({
      ...prev,
      loading: true
    }));
    bsnpartnersapi
      .audiLogsSearch('clients', {
        partner_id: partnerId,
        _limit: searchLimit,
        ...(clientName ? { name: clientName } : {})
      })
      .then(res => {
        setClients(prev => ({
          ...prev,
          options: res?.data?.data || []
        }));
      })
      .catch(e => {
        enqueueAlertSnackbar('failed to load clients', { props: { severity: 'error' } });
      })
      .finally(() =>
        setClients(prev => ({
          ...prev,
          loading: false
        }))
      );
  };

  const getEmailTemplates = () => {
    setEmailTemplates(prev => ({
      ...prev,
      loading: true
    }));
    contentAdmin
      .getTemplates()
      .then(res => {
        setEmailTemplates(prev => ({
          ...prev,
          options: res?.data?.templates || []
        }));
      })
      .catch(e => {
        enqueueAlertSnackbar('failed to load email templates', 'error');
      })
      .finally(() =>
        setEmailTemplates(prev => ({
          ...prev,
          loading: false
        }))
      );
  };

  const onSearchPartner = partnerName => {
    if (!partnerName) {
      setClients(prev => ({
        ...prev,
        selected: null
      }));
    }
    getPartners(partnerName);
  };

  const onSearchClient = clientName => {
    setClients(prev => ({
      ...prev,
      loading: true
    }));

    partners.selected?.id ? getClients(partners.selected.id, clientName) : setClients(prev => ({
      ...prev,
      loading: false
    }));
  };

  useEffect(() => {
    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (partners.search || partners.search === '') && onSearchPartner(searchPartnerDebounce.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPartnerDebounce.value]);

  useEffect(() => {
    (clients.search || clients.search === '') && onSearchClient(searchClientDebounce.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchClientDebounce.value]);

  const handleChange = e => {
    const { name, value } = e.target;
    setInternalFilters(oldValue => {
      return {
        ...oldValue,
        [name]: value
      };
    });
  };

  const handleDateChange = (date) => {
    // Account for date off by 1 error in differing Timezones
    const delivery_date = date ? createDateAsUTC(convertDateToUTC(date)) : null;
    setInternalFilters(oldValue => {
      return {
        ...oldValue,
        delivery_date
      };
    });
  };

  return (
    <Container open={open}>
      <Button variant="contained" onClick={() => setOpen(!open)}>
        Filters
      </Button>
      {open && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextFieldsContainer>
            <FilterField
              id="partners"
              options={partners.options}
              label="Partner Name"
              getOptionLabel={option => option.name}
              value={partners.selected}
              loading={partners.loading}
              open={partners.open}
              onOpen={() => {
                setPartners(prev => ({
                  ...prev,
                  open: true
                }));
                partners.length === 0 && getPartners();
              }}
              onClose={() => {
                setPartners(prev => ({
                  ...prev,
                  open: false
                }));
              }}
              onChange={(e, val, reason) => {
                setPartners(prev => ({
                  ...prev,
                  selected: val
                }));
                if (!val) {
                  setClients(prev => ({
                    ...prev,
                    selected: null,
                    search: ''
                  }));
                }
                else {
                  getClients(val?.id);
                }

                if (reason === 'clear') {
                  setPartners(prev => ({
                    ...prev,
                    search: ''
                  }));
                }
              }}
              onChangeTextField={e => {
                const { target } = e;
                setPartners(prev => ({
                  ...prev,
                  search: target.value
                }));
              }}
              popUpZIndex={customZIndex}
              variant="standard"
              popPlacement="bottom-start"
            />
            <FilterField
              id="clients"
              options={clients.options}
              disabled={!partners?.selected?.id}
              value={clients.selected}
              inputValue={clients.search ? clients.search : ''}
              label="Client Name"
              loading={clients.loading}
              open={clients.open}
              onOpen={() => {
                setClients(prev => ({
                  ...prev,
                  open: true
                }));
                clients.length === 0 && getClients(partners.selected.id);
              }}
              onClose={() => {
                setClients(prev => ({
                  ...prev,
                  open: false
                }));
              }}
              getOptionLabel={option => option.name}
              onChange={(e, val, reason) => {
                setClients(prev => ({
                  ...prev,
                  selected: val,
                  search: val ? val.name : prev.search
                }));
                if (reason === 'clear') {
                  setClients(prev => ({
                    ...prev,
                    search: ''
                  }));
                }
              }}
              onChangeTextField={e => {
                const { target } = e;
                setClients(prev => ({
                  ...prev,
                  search: target.value
                }));
              }}
              popUpZIndex={customZIndex}
              variant="standard"
            />
            <FilterField
              id="email_templates"
              options={emailTemplates.options}
              value={emailTemplates.selected}
              label="Email Temaplate"
              loading={emailTemplates.loading}
              open={emailTemplates.open}
              onOpen={() => {
                setEmailTemplates(prev => ({
                  ...prev,
                  open: true
                }));
                emailTemplates.options.length === 0 && getEmailTemplates();
              }}
              onClose={() => {
                setEmailTemplates(prev => ({
                  ...prev,
                  open: false
                }));
              }}
              getOptionLabel={option => option.name}
              onChange={(e, val, reason) => {
                setEmailTemplates(prev => ({
                  ...prev,
                  selected: val
                }));
                if (reason === 'clear') {
                  setEmailTemplates(prev => ({
                    ...prev,
                    search: ''
                  }));
                }
              }}
              onChangeTextField={e => {
                const { target } = e;
                setEmailTemplates(prev => ({
                  ...prev,
                  search: target.value
                }));
              }}
              popUpZIndex={customZIndex}
              variant="standard"
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="selecting_sorting_field_label">Sorting Field</InputLabel>
              <Select
                labelId="selecting_sorting_field_label"
                id="selecting_sorting_field"
                name="sortingField"
                value={internalFilters.sortingField}
                onChange={handleChange}
              >
                <MenuItem value="partner_name">Partner name</MenuItem>
                <MenuItem value="client_name">Client name</MenuItem>
                <MenuItem value="delivery_date">Delivery Date</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="selecting_sorting_direction_label">Sorting Direction</InputLabel>
              <Select
                labelId="selecting_sorting_direction_label"
                id="selecting_sorting_direction"
                name="sortingDirection"
                value={internalFilters.sortingDirection}
                onChange={handleChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
            <DatePicker
              autoOk
              clearable
              disableFuture
              style={{ margin: 0 }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Delivery Date"
              value={internalFilters.delivery_date}
              onChange={(date) => handleDateChange(date)}
              InputProps={{
                endAdornment: (
                  <div
                    onClick={() => handleDateChange(null)}
                    style={{ marginRight: 20, cursor: 'pointer' }}
                  >
                    <ClearIcon />
                  </div>
                )
              }}
            />
            <div className="applyFiltersBtn">
              <Button
                onClick={() => {
                  applyFilters({
                    ...internalFilters,
                    partner_id: partners.selected?.id || '',
                    client_id: clients.selected?.id || '',
                    email_template: emailTemplates.selected?.name || ''
                  });
                }}
              >
                Apply filters
              </Button>
            </div>
          </TextFieldsContainer>
        </MuiPickersUtilsProvider>
      )}
    </Container>
  );
};

FiltersBar.propTypes = {
  filters: PropTypes.shape({
    partner_id: PropTypes.string.isRequired,
    partner_name: PropTypes.string.isRequired,
    client_name: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired,
    delivery_date: PropTypes.string.isRequired,
    sortingField: PropTypes.string.isRequired,
    sortingDirection: PropTypes.string.isRequired
  }).isRequired,
  applyFilters: PropTypes.func.isRequired
};

export default FiltersBar;
