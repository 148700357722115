import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonCancel } from 'components';
import { isValidEmail } from 'helpers';
import { matchIsValidTel } from 'mui-tel-input';

import {
  Grid,
  Paper,
  Typography,
  Button,
  MenuItem,
  TextField,
  PhoneNumberInput,
  ArrowRightIcon,
  SaveIcon,
  FormControlLabel,
  Checkbox,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';

const INDUSTRY_CHOICES = [
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Apparel', value: 'Apparel' },
  { label: 'Banking', value: 'Banking' },
  { label: 'Biotechnology', value: 'Biotechnology' },
  { label: 'Chemicals', value: 'Chemicals' },
  { label: 'Communications', value: 'Communications' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consulting', value: 'Consulting' },
  { label: 'Education', value: 'Education' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Environmental', value: 'Environmental' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Food & Beverage', value: 'Food & Beverage' },
  { label: 'Government', value: 'Government' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Legal', value: 'Legal' },
  { label: 'Machinery', value: 'Machinery' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Media', value: 'Media' },
  { label: 'Not For Profit', value: 'Not For Profit' },
  { label: 'Other', value: 'Other' },
  { label: 'Recreation', value: 'Recreation' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Shipping', value: 'Shipping' },
  { label: 'Technology', value: 'Technology' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Utilities', value: 'Utilities' }
];

const ORGANIZATION_TYPE_CHOICES = [
  {
    label: 'Attorney/Legal supporting a Medical Organization',
    value: 'Attorney/Legal supporting a Medical Organization'
  },
  { label: 'Billing, Claims or Coding Services', value: 'Billing, Claims or Coding Services' },
  { label: 'Clearinghouse', value: 'Clearinghouse' },
  {
    label: 'CPA or Financial Entity supporting a Medical Organization',
    value: 'CPA or Financial Entity supporting a Medical Organization'
  },
  { label: 'Consultant', value: 'Consultant' },
  { label: 'Disposal or Shredding Services', value: 'Disposal or Shredding Services' },
  {
    label: 'EHR/EMR/Practice Management Application',
    value: 'EHR/EMR/Practice Management Application'
  },
  { label: 'Health Information Exchange', value: 'Health Information Exchange' },
  { label: 'Health Plan', value: 'Health Plan' },
  { label: 'Healthcare Provider', value: 'Healthcare Provider' },
  {
    label: 'Information Technology (IT) Support',
    value: 'Information Technology (IT) Support'
  },
  { label: 'Medical Equipment Vendor/Servicer', value: 'Medical Equipment Vendor/Servicer' },
  { label: 'Medical Transcription', value: 'Medical Transcription' },
  { label: 'Other Application that Stores ePHI', value: 'Other Application that Stores ePHI' },
  { label: 'Other', value: 'Other' },
  {
    label: 'Transportation or Storage of Medical Information',
    value: 'Transportation or Storage of Medical Information'
  }
];

const LOCATIONS = [
  {
    number: 1,
    name: 'first'
  },
  {
    number: 2,
    name: 'second'
  },
  {
    number: 3,
    name: 'third'
  },
  {
    number: 4,
    name: 'fourth'
  },
  {
    number: 5,
    name: 'fifth'
  }
];

function getLocation(num) {
  return LOCATIONS.find(item => item.number === num);
}

const NameAndLocation = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex, dirty, phoneCountryCode, setPhoneCountryCode }) => {
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleChangePhones = (value, info, name) => {
    setPhoneCountryCode(prevState => {
      return { ...prevState, [name]: `+${info.countryCallingCode}` }
    });
    setState(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  const handleChangeState = (event, val, isNumericInput) => {
    let { name, value } = event.target;
    if (isNumericInput) value = value.replace(/[^0-9]/g, '');
    const newState = { ...state, [name]: value };

    if (name === 'administrator_email') {
      if (value && !isValidEmail(value)) setInvalidEmail(true);
      else setInvalidEmail(false);
    }
    if (name === 'industry' && value !== 'Other' && state.industry_other) {
      newState.industry_other = '';
    }
    if (name === 'organization_type' && value !== 'Other' && state.organization_other_type) {
      newState.organization_other_type = '';
    }
    if (name === 'administrator_phone_alt_ext' || name ==='administrator_phone_ext') {
      // replace all non-numeric characters
      newState[name] = value.replace(/\D/g, '');
    }

    setState(newState);
  };

  const handleAddressChange = e => {
    const { name, value } = e.target;
    setState(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  const addRemoveAddresses = (e, num) => {
    const { name, checked } = e.target;

    // Hide locations below the unchecked one
    LOCATIONS.map(item => {
      if (item.number > num && !checked) {
        setState(prevState => {
          return { ...prevState, [`${getLocation(item.number).name}_location`]: 'No' };
        });
      }
    });

    setState(prevState => {
      return { ...prevState, [name]: checked ? 'Yes' : 'No' };
    });
  };

  const save = action => {
    if (invalidEmail) {
      enqueueAlertSnackbar('Please enter a valid email', { props: { severity: 'warning' } });
      return;
    }
    onSave(action, currentTabIndex);
  };
  function isValidPhoneNumber(name) {
    if (typeof state[name] !== 'string') return false
    if (!state[name] || state[name] === phoneCountryCode[name]) return true
    return matchIsValidTel(state[name])
  }
  const validNumberOfEmployees = state.number_employees === null || state.number_employees >= 1;
  const validAdministratorPhone = isValidPhoneNumber('administrator_phone');
  const validAdministratorPhoneAlt = isValidPhoneNumber('administrator_phone_alt');

  useEffect(() => {
    // Check if there's an email on the state and if it's valid.
    if(state?.administrator_email && !isValidEmail(state?.administrator_email))
      setInvalidEmail(true)
  }, [state])

  return (
    <>
      {/* Organization info */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container alignItems="start">
              {/* Organization info Title */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                sx={{ marginBottom: 3 }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justify="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant="h3" sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      1.Organization info
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {!isHSN ? (
                <>
                  {/* Provider */}
                  <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                    <TextField
                      fullWidth
                      select
                      id="industry"
                      label="Provider *"
                      placeholder="Provider *"
                      name="industry"
                      value={state.industry}
                      onChange={handleChangeState}
                    >
                      {INDUSTRY_CHOICES?.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* Other Industry */}
                  {state.industry === 'Other' && (
                    <>
                      <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state.industry_other}
                          name="industry_other"
                          label="Other Industry"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Grid>
                      <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* Organization Type */}
                  <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                    <TextField
                      fullWidth
                      select
                      id="organization_type"
                      label="Organization Type *"
                      placeholder="Organization Type *"
                      name="organization_type"
                      value={state.organization_type}
                      onChange={handleChangeState}
                    >
                      {ORGANIZATION_TYPE_CHOICES?.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* Other Organization Type */}
                  {state.organization_type === 'Other' && (
                    <>
                      <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state.organization_other_type}
                          name="organization_other_type"
                          label="Other Organization Type"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Grid>
                      <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4} />
                    </>
                  )}
                </>
              )}

              {/* How many employees (total) */}
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  value={state.number_employees}
                  name="number_employees"
                  label="How many employees (total) *"
                  fullWidth
                  onKeyDown={(e)=>{
                    if(/^[eE]$/.test(e.key)){
                      e.preventDefault()
                    }
                  }}
                  onChange={e => handleChangeState(e, null, true)}
                  inputProps={{ min: 1, step: 1, maxLength: 7 }}
                  error={!validNumberOfEmployees}
                  helperText={validNumberOfEmployees ? ' ' : 'Number of employees cannot be less than 1'}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Administrator info */}
      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ borderRadius: 2, paddingBottom: 2 }}>
            <Grid container>
              {/* Administrator info title */}

              <Grid
                item
                sx={{ marginBottom: 2 }}
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justify="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant="h3" sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      2.Administrator info
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Organization’s administrator name */}
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  value={state.administrator_name}
                  name="administrator_name"
                  label="Organization’s administrator name *"
                  fullWidth
                  onChange={handleChangeState}
                />
              </Grid>

              {/* Organization’s administrator email */}
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  value={state.administrator_email}
                  name="administrator_email"
                  label="Organization’s administrator email *"
                  fullWidth
                  onChange={handleChangeState}
                  error={invalidEmail}
                  helperText={invalidEmail && 'Please enter a valid email address'}
                />
              </Grid>
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4} />

              {/* Locations */}
              {LOCATIONS.map(item => {
                const { number, name } = item;
                const locationName = number === 1 ? '' : name;
                const prefix = number === 1 ? '' : `${locationName}_`;
                const requiredSign = number === 1 ? ' *' : '';

                const nextLocationName = getLocation(number + 1)?.name;
                const nextPrefix = `${nextLocationName}_`;

                if (number > 1 && state[`${prefix}location`] !== 'Yes') {
                  return null;
                }

                return (
                  <React.Fragment key={item.name}>
                    {/* Location title */}
                    {number > 1 && (
                      <Grid
                        borderTop={theme => `1px solid ${theme.palette.divider}`}
                        sx={{ padding: 2 }}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Typography variant="p" sx={{ textTransform: 'capitalize' }}>
                          {locationName} Location
                        </Typography>
                      </Grid>
                    )}

                    {/* Zip code */}
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state[`${prefix}zip`]}
                        name={`${prefix}zip`}
                        label={`Zip code${requiredSign}`}
                        fullWidth
                        onChange={handleAddressChange}
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4} />
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4} />

                    {/* City */}
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state[`${prefix}city`]}
                        name={`${prefix}city`}
                        label={`City${requiredSign}`}
                        fullWidth
                        onChange={handleAddressChange}
                      />
                    </Grid>

                    {/* State */}
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state[`${prefix}state`]}
                        name={`${prefix}state`}
                        label={`State${requiredSign}`}
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        onChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4} />

                    {/* Address 1 */}
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state[`${prefix}address_1`]}
                        name={`${prefix}address_1`}
                        label={`Address 1${requiredSign}`}
                        fullWidth
                        onChange={handleAddressChange}
                      />
                    </Grid>

                    {/* Address 2 */}
                    <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        value={state[`${prefix}address_2`]}
                        name={`${prefix}address_2`}
                        label="Address 2"
                        fullWidth
                        onChange={handleAddressChange}
                      />
                    </Grid>

                    {/* Next location checkbox */}
                    {number < 5 && (
                      <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormControlLabel
                          value={state[`${nextPrefix}location`] === 'Yes'}
                          checked={state[`${nextPrefix}location`] === 'Yes'}
                          control={<Checkbox color="primary" />}
                          name={`${nextPrefix}location`}
                          label={`Do you have a ${nextLocationName} location?`}
                          labelPlacement="end"
                          onChange={e => addRemoveAddresses(e, number + 1)}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Contact */}
      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ borderRadius: 2, paddingBottom: 2 }}>
            <Grid container>
              {/* Contact title */}
              <Grid
                item
                sx={{ marginBottom: 3 }}
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justify="space-between">
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant="h3" sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      3.Contact
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Administrator phone (Primary) */}
              <Grid item sx={{ px: 2, pt: 2, margin: 0 }} xs={12} sm={12} md={4} lg={4} xl={4} mt={1}>
                <PhoneNumberInput
                  variant="outlined"
                  value={state.administrator_phone}
                  name="administrator_phone"
                  label="Administrator phone (Primary)"
                  onBlur={e => 
                    {if(e.target.value === '+' ) {
                      setState(prevState => ({ ...prevState, administrator_phone : '+1' }));
                      setPhoneCountryCode(prevState => ({ ...prevState, administrator_phone : '+1' }));
                    }}
                  }
                  fullWidth
                  onChange={(val, info) => handleChangePhones(val, info, 'administrator_phone')}
                  error={!validAdministratorPhone}
                  helperText={validAdministratorPhone ? ' ' : 'Please enter a valid phone number'}
                  defaultCountry="US"
                  countryCodeEditable={false}
                />
              </Grid>

              {/* Ext */}
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={2} lg={2} xl={2}>
                <TextField
                  value={state.administrator_phone_ext}
                  name="administrator_phone_ext"
                  label="Ext"
                  fullWidth
                  onChange={handleChangeState}
                  integerOnly
                  inputProps={{
                    maxLength: 5,
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Grid>
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={6} lg={6} xl={6} />

              {/* Administrator phone (Alternative) */}
              <Grid item sx={{ px: 2, pt: 2, margin: 0 }} xs={12} sm={12} md={4} lg={4} xl={4} mt={1}>
                <PhoneNumberInput
                  variant="outlined"
                  value={state.administrator_phone_alt}
                  name="administrator_phone_alt"
                  label="Administrator phone (Alternative)"
                  onBlur={e => 
                    {if(e.target.value === '+' ) {
                      setState(prevState => ({ ...prevState, administrator_phone_alt : '+1' }));
                      setPhoneCountryCode(prevState => ({ ...prevState, administrator_phone_alt : '+1' }));
                    }}
                  }
                  fullWidth
                  onChange={(val, info) => handleChangePhones(val, info, 'administrator_phone_alt')}
                  error={!validAdministratorPhoneAlt}
                  helperText={validAdministratorPhoneAlt ? ' ' : 'Please enter a valid phone number'}
                  defaultCountry="US"
                  countryCodeEditable={false}
                />
              </Grid>

              {/* Ext */}
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={2} lg={2} xl={2}>
                <TextField
                  value={state.administrator_phone_alt_ext}
                  name="administrator_phone_alt_ext"
                  label="Ext"
                  fullWidth
                  onChange={handleChangeState}
                  integerOnly
                  inputProps={{
                    maxLength: 5,
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Action buttons */}
        <Grid
          item
          sx={{ marginTop: 3 }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid container spacing={4} display="flex" justifyContent="flex-end">
            <Grid
              sx={{ margin: 2 }}
              display="flex"
              justifyContent="flex-end"
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              <ButtonCancel variant="text" onClick={e => handleClose()}>
                Exit
              </ButtonCancel>
              <Button color="info" startIcon={<SaveIcon />} variant="contained" disableElevation onClick={save} disabled={!dirty}>
                Save
              </Button>
              <Button
                color="success"
                startIcon={<ArrowRightIcon />}
                variant="contained"
                disableElevation
                sx={{ marginLeft: '30px' }}
                onClick={() => save('next')}
              >
                Save and Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

NameAndLocation.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default NameAndLocation;
