import axios from 'axios';
import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';
import { dataParams } from 'helpers';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = {
  darkWebLicense: process.env.REACT_APP_BASE_URL_9,
  darkWebPurchase: process.env.REACT_APP_BASE_URL_20,
  taxExempt: process.env.REACT_APP_BASE_URL_14,
  newsletters: process.env.REACT_APP_BASE_URL_1,
  microTrainings: process.env.REACT_APP_BASE_URL_1
};

const baseURL = resource => `https://${apiGWID[resource]}.${base}/${env}/`;

const admin = {};
const partners = {};
const partnersTaxExempt = {};
const newsletters = {};
const microTrainings = {};

const requestOptions = (resource, url, params, data) => {
  const base = baseURL(resource);
  return {
    url: `${base}${url}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    params,
    data
  };
};

partners.getDarkWebLicense = partnerId => {
  return apiClient.get(requestOptions('darkWebLicense', `admin/darkWebLicenseData/${partnerId}`));
};

partners.applyCoupon = (partnerId, record) => {
  return apiClient.post(requestOptions('darkWebLicense', `admin/darkWebLicenseData/${partnerId}`, {}, record));
};

partners.deleteCoupon = (partnerId, record) => {
  return apiClient.delete(requestOptions('darkWebLicense', `admin/darkWebLicenseData/${partnerId}`, {}, record));
};

partners.updateDWMLicense = (partnerId, record) => {
  return apiClient.post(requestOptions('darkWebPurchase', `admin/updateDWMLicense/${partnerId}`, {}, record));
};

partnersTaxExempt.getList = (params, partnerId) => {
  return apiClient.get(requestOptions('taxExempt', `admin/certificates/${partnerId}`));
};

partnersTaxExempt.getCertificate = (partnerId, certificateId) => {
  return apiClient.get(requestOptions('taxExempt', `admin/certificate/${partnerId}`, { certificate: certificateId }));
};

partners.getBilling = partnerId => {
  return apiClient.get(requestOptions('taxExempt', `admin/billing/${partnerId}`));
};

// newsletters apis
newsletters.getList = params => {
  const newParams = dataParams({ ...params, startAtZero: true });
  return apiClient.get(requestOptions('newsletters', 'myDashboard/snldocuments', newParams));
};

newsletters.delete = ids => {
  return apiClient.delete(requestOptions('newsletters', 'myDashboard/snldocuments', {}, { type: 'delete', ids }));
};

newsletters.information = newsletterId => {
  return apiClient.get(requestOptions('newsletters', `myDashboard/snldocuments/${newsletterId}`));
};

newsletters.update = (newsletterId, record) => {
  return apiClient.put(requestOptions('newsletters', `myDashboard/snldocuments/${newsletterId}`, {}, record));
};

newsletters.create = record => {
  return apiClient.post(requestOptions('newsletters', `myDashboard/snldocuments`, {}, record));
};

newsletters.actions = (type, id) => {
  return apiClient.put(requestOptions('newsletters', 'myDashboard/snldocuments', {}, { type, id }));
};

newsletters.configureEmail = record => {
  return apiClient.put(requestOptions('newsletters', `myDashboard/snldocuments`, {}, record));
};

// microTrainings apis
microTrainings.getList = params => {
  const newParams = dataParams({ ...params, startAtZero: true });
  return apiClient.get(requestOptions('microTrainings', 'myDashboard/adminMicroTrainings', newParams));
};

microTrainings.delete = ids => {
  return apiClient.delete(
    requestOptions('microTrainings', 'myDashboard/adminMicroTrainings', {}, { type: 'delete', ids })
  );
};

microTrainings.information = newsletterId => {
  return apiClient.get(requestOptions('microTrainings', `myDashboard/adminMicroTrainings/${newsletterId}`));
};

microTrainings.update = (newsletterId, record) => {
  return apiClient.put(requestOptions('microTrainings', `myDashboard/adminMicroTrainings/${newsletterId}`, {}, record));
};

microTrainings.create = record => {
  return apiClient.post(requestOptions('microTrainings', `myDashboard/adminMicroTrainings`, {}, record));
};

microTrainings.actions = (type, id) => {
  return apiClient.put(requestOptions('microTrainings', 'myDashboard/adminMicroTrainings', {}, { type, id }));
};

microTrainings.configureEmail = record => {
  return apiClient.put(requestOptions('microTrainings', `myDashboard/adminMicroTrainings`, {}, record));
};

microTrainings.checkPublishedDate = (date) => {
  return apiClient.post(requestOptions('microTrainings', `myDashboard/adminMicroTrainings/validate-creation`, {}, date));
};

// client admin tab
admin.getInsuranceCancellation = clientId => {
  return apiClient.get(requestOptions('darkWebPurchase', `admin/insuranceCancellation/${clientId}`));
};

admin.createInsuranceCancellation = (clientId, record) => {
  return apiClient.post(requestOptions('darkWebPurchase', `admin/insuranceCancellation/${clientId}`, {}, record));
};

admin.getDwba = clientId => {
  // eslint-disable-next-line no-shadow
  const base = `${process.env.REACT_NEW_BASE_URL}darkwebmonitoringservices/`;
  return apiClient.get({
    url: `${base}admin/dwbaActivities/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    }
  });
};

admin.clearDwba = (clientId, activity) => {
  // eslint-disable-next-line no-shadow
  const base = `${process.env.REACT_NEW_BASE_URL}darkwebmonitoringservices/`;
  return apiClient.post({
    url: `${base}admin/dwbaActivities/${clientId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    params: {},
    data: { activity }
  });
};

admin.pressignedUploadFile = (resource, id, filename, type) => {
  return apiClient.post(requestOptions(resource, `myCompany/presignedUpload/${id}`, {}, { filename, type }));
};

admin.postUrl = (url, data, filetype) => {
  const requestOptions = {
    url,
    method: 'POST',
    headers: { 'Content-Type': filetype },
    data
  };

  return axios(requestOptions)
    .then(({ data: newData }) => ({ data: newData }))
    .catch(error => console.log(error));
};

admin.getDomainsRange = (clientId) => {
  return apiClient.get(requestOptions('darkWebLicense', `admin/dark-web-max-domain/${clientId}`));
};

admin.postDomainsRange = (clientId, value) => {
  return apiClient.post(requestOptions('darkWebLicense', `admin/dark-web-max-domain/${clientId}`, {}, {client_max_domains: value} ));
};

admin.partners = partners;
admin.partnersTaxExempt = partnersTaxExempt;
admin.newsletters = newsletters;
admin.microTrainings = microTrainings;

export default admin;
