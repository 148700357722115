import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataGet, useLocation, useId } from 'hooks';
import {
  dataProvider,
  arrayToChoices,
  validURL,
  admin as adminApi,
  isObjectEmpty,
  objectsEqual,
  setSessionStorage
} from 'helpers';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { useAdminList } from 'apps/admin/hooks';
import useHasInternet from 'hooks/useHasInternet';
import { BSN_SET_CLIENT_ACCESS } from 'conf';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const accountTypes = [
  { value: 'Unlimited Training', label: 'Unlimited Training (UT)' },
  { value: 'BPP', label: 'Breach Prevention Platform (BPP)' }
];

const UTproductType = [{ value: 'Unlimited Cybersecurity Training', label: 'Unlimited Cybersecurity Training' }];

const useAdminCapabilities = () => {
  const { app, item } = useLocation();
  const dispatchRx = useDispatch();
  const hasInternet = useHasInternet();
  const partnerId = useId({ key: 'partnerId' });

  const [record, setRecord] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [accountInfo, setAccountInfo] = useState({});
  const [dwba, setDwba] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDWBA, setLoadingDWBA] = useState(false);
  const [trainingsOptions, setTrainingsOptions] = useState([]);
  const [productCodeOptions, setProductCodeOptions] = useState([]);
  const [isProductCodeOptionsLoading, setIsProductCodeOptionsLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [validLink, setValidLink] = useState(true);
  const [campaignUsed, setCampaignUsed] = useState(0);
  const [domainsRange, setDomainsRange] = useState({
    min: null,
    max: null,
    current: null
  });

  const useNoteList = useAdminList('clientNotes', item);
  const { data: productsData, isSuccess: isSuccessPd, refetch } = useDataGet({
    app: 'admin',
    tab: 'productsOptions',
    item,
    params: { product_type: record?.product_type }
  });
  const { data: trainingData, isSuccess: isSuccessTd } = useDataGet({ app, tab: 'trainingFilter', item });

  const setClientData = clientData => {
    // There are several fields on clientData not needs to include in the record
    // including in record just needed info
    const data = {
      account_type: clientData.account_type,
      active: clientData.active,
      created: clientData.created,
      is_real: clientData.is_real,
      insurance: clientData.insurance,
      link: clientData.link,
      pax8_customer_id: clientData.pax8_customer_id,
      pax8_partner_id: clientData.pax8_partner_id,
      pax8_product_code: clientData.pax8_product_code,
      pax8_subscription_id: clientData.pax8_subscription_id,
      portal_client_id: clientData.portal_client_id,
      portal_partner_id: clientData.portal_partner_id,
      product_type: clientData.product_type,
      moodle_course_id: clientData.moodle_course_id,
      name: clientData.name,
      no_of_employees: clientData.no_of_employees,
      special_pricing_option: clientData.special_pricing_option,
      user_count: clientData.user_count,
      new_sra: clientData.new_sra,
      ra_consulting: clientData?.ra_consulting,
      ra_date_completed: clientData?.risk_assessment_status
    };
    setRecord(data);
    setClientInfo(data);
    setAccountInfo(data);
  };

  const clientInfoKeys = [
    'link',
    'no_of_employees',
    'user_count',
    'is_real',
    'name',
    'active',
    'account_active'
  ];

  const extractFields = (record, keysToExtract) => {
    return Object.fromEntries(
      keysToExtract.map(key => [key, record[key]])
    );
  };

  const hasSameState = (originalValue, currentValue) => {
    // "" and null also should be considered as equal is this condition
    return originalValue === currentValue || !originalValue && !currentValue;
  };

  const getDwbaData = useCallback(() => {
    if (!isObjectEmpty(dwba)) return;
    setLoadingDWBA(true);
    adminApi.getDwba(item)
      .then((resp) => setDwba(resp.data))
      .catch(err =>
        enqueueAlertSnackbar(err?.response?.data?.description || 'Something went wrong', {
          props: { severity: 'error' }
        })
      )
      .finally(() => setLoadingDWBA(false));
  }, [item, dwba]);

  const getDomainsRange= useCallback(async () => {
    try {
      const resp = await adminApi.getDomainsRange(item);
      setDomainsRange({ min: resp.data.client_allowed_domains_range.min, max: resp.data.client_allowed_domains_range.max, current: resp.data.client_max_domains ?? "" })
    } catch (err) {
      enqueueAlertSnackbar(err?.response?.data?.description || 'Something went wrong', {props: { severity: 'error' }})
    }
  }, [item]);

  const postDomainsRange = async (value) => {
    try {
      await adminApi.postDomainsRange(item, value)
      await getDomainsRange();
      enqueueAlertSnackbar('Domains allowed has been updated', { props: { severity: 'success' } });
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.description || 'Something went wrong', {
        props: { severity: 'error' }
      });
    }
  }

  const getClientInformation = useCallback(() => {
    setLoading(true);
    bsnclientservices
      .getClientInfo(item)
      .then(data => setClientData(data))
      .catch(err => enqueueAlertSnackbar(err?.message || 'Something went wrong', { props: { severity: 'error' } }))
      .finally(() => setLoading(false));
  }, [item]);

  const getCampaignsUsed = useCallback(() => {
    dataProvider.getOne('clients', `phishingResetCampaign/${item}`, null).then(resp => setCampaignUsed(resp.data));
  }, [record]);

  useEffect(() => {
    getClientInformation();
    getDomainsRange();
  }, [getClientInformation, getDomainsRange]);

  useEffect(() => {
    getDwbaData();
  }, [getDwbaData]);

  useEffect(() => {
    if (trainingData?.type?.annual && isSuccessTd) {
      const trainingChoices = arrayToChoices(trainingData?.type?.annual, 'training_id', 'value');
      setTrainingsOptions(trainingChoices);
    }
  }, [isSuccessTd, trainingData]);

  useEffect(() => {
    setIsProductCodeOptionsLoading(true);
    if (isSuccessPd) {
      const currentProdType =
        record?.product_type === 'Breach Prevention Platform (BPP)' ? 'BPP' : record?.product_type;
      const codes = productsData.account_type?.[currentProdType]
        ? arrayToChoices(productsData.account_type[currentProdType], 'product_code', 'product_code')
        : [];
      setProductCodeOptions(codes);
      setIsProductCodeOptionsLoading(false);

      if (!productTypes.length) {
        setProductTypes(
          productsData.product_type.map(key => {
            return { value: key, label: key };
          })
        );
      }
    }
  }, [isSuccessPd, productsData, record?.product_type]);

  useEffect(() => {
    if (record.account_type === 'Unlimited Training') getCampaignsUsed();
  }, [record.account_type, getCampaignsUsed]);

  const dispatch = {};

  dispatch.disableSaveClientInfo = () => {
    return (
      !record?.name?.trim()?.length > 0 ||
      !validLink ||
      (record?.name === clientInfo?.name &&
        Boolean(record?.active) === Boolean(clientInfo?.active) &&
        Boolean(record?.is_real) === Boolean(clientInfo?.is_real) &&
        record?.link === clientInfo?.link)
    );
  };

  dispatch.disableSaveAccountInfo = () => {
    return (
      !record?.product_type ||
      (record?.account_type !== 'Unlimited Training' && !record?.pax8_product_code) ||
      (record?.account_type === accountInfo?.account_type &&
        Boolean(record?.special_pricing_option) === Boolean(accountInfo?.special_pricing_option) &&
        record?.product_type === accountInfo?.product_type &&
        record?.pax8_product_code === accountInfo?.pax8_product_code &&
        hasSameState(record?.pax8_subscription_id, accountInfo?.pax8_subscription_id) &&
        hasSameState(record?.pax8_customer_id, accountInfo?.pax8_customer_id) &&
        hasSameState(record?.pax8_partner_id, accountInfo?.pax8_partner_id)
      )
    );
  };

  const refetchClient = () => {
    dataProvider
      .getOne('clients', `list/${partnerId}`, { _filter: `id:${String(item)}` })
      .then((res) => {

        hasInternet(() => {
          const client = res.data?.clients?.[0] || {};
          const { access = null, is_hsn = null } = client;
          if (!access) return;

          dispatchRx({
            type: BSN_SET_CLIENT_ACCESS,
            payload: { access, is_hsn },
          });
        });
      });
  };


  const handleSuccessOnSave = (res) => {
    if (res?.code >= 400) {
      enqueueAlertSnackbar(res?.description, { props: { severity: 'warning' } });
      return;
    }
    if (record.notes) {
      useNoteList.refetch();
      setRecord({ ...record, notes: '' });
    }
    enqueueAlertSnackbar(
      'The information was successfully updated',
      { props: { severity: 'success' } }
    );
    refetchClient();
  }

  dispatch.saveClientInfo = () => {
    setLoading(true);
    const clientInfoPayload = extractFields(record, clientInfoKeys);

    dataProvider
      .update('clients', `information/${item}`, clientInfoPayload)
      .then(res => {
        handleSuccessOnSave(res);
        setClientInfo(clientInfoPayload);
      })
      .finally(() => setLoading(false));
  }

  dispatch.save = () => {
    setLoading(true);
    const accountInfoKeys = Object.keys(record).filter(key => !clientInfoKeys.includes(key));
    const accountInfoPayload = extractFields(record, accountInfoKeys);

    dataProvider
      .update('clients', `information/${item}`, accountInfoPayload)
      .then(res => {
        handleSuccessOnSave(res);
        setAccountInfo(accountInfoPayload);
      })
      .finally(() => setLoading(false));
  };

  dispatch.clearDwba = activity => {
    setLoadingDWBA(true);
    adminApi
      .clearDwba(item, activity)
      .then(resp => {
        setDwba(old => {
          return { ...old, ...(resp.data.data ?? {}) };
        });
        enqueueAlertSnackbar(
          resp.data.message || resp.data.status,
          { props: { severity: 'success' } }
        );
      })
      .catch(err => {
        enqueueAlertSnackbar(
          err?.response?.data?.status || 'Something went wrong',
          { props: { severity: 'error' } }
        )
      })
      .finally(() => setLoadingDWBA(false));
  };

  dispatch.onChange = (name, value) => {
    setRecord({ ...record, [name]: value });
  };

  dispatch.onChangeActiveStatus = (name, value) => {
    setRecord({
      ...record,
      [name]: value,
      account_active: value
    });
  };

  dispatch.onChangeLink = (name, value) => {
    setValidLink(!value || validURL(value));
    dispatch.onChange(name, value);
  };

  dispatch.onChangePrice = checked => {
    if (checked) setOpenDialog(true);
    else dispatch.onChange('special_pricing_option', false);
  };

  dispatch.onConfirmPriceOption = () => {
    dispatch.onChange('special_pricing_option', true);
    setOpenDialog(false);
  };

  dispatch.onChangeAccountType = (name, value) => {
    setRecord({
      ...record,
      [name]: value,
      product_type: null,
      pax8_product_code: null
    });
  };

  dispatch.onChangeProductType = (name, value) => {
    const newvalues = {
      [name]: value,
      pax8_product_code: null
    };

    if (
      (value !== 'HIPAA Compliance' || value !== 'Unlimited Cybersecurity Training') &&
      record?.special_pricing_option
    ) {
      newvalues.special_pricing_option = false;
    }

    setRecord({ ...record, ...newvalues });
  };

  dispatch.setOpenDialog = value => setOpenDialog(value);

  dispatch.setOpenReset = value => setOpenReset(value);

  dispatch.onResetCampaign = () => {
    setOpenReset(false);

    dataProvider.update('clients', `phishingResetCampaign/${item}`, null).then(resp => {
      setCampaignUsed(0);
      enqueueAlertSnackbar(resp.data.description, { props: { severity: 'success' } });
    });
  };

  dispatch.onMoveClient = ({ partnerId, partnerName }) => {
    usersAPI
      .moveClient(item, partnerId)
      .then(resp => {
        getClientInformation();
        enqueueAlertSnackbar(
          resp.data?.description || 'The client has been successfully moved',
          { props: { severity: 'success'} }
        );
        refetch();

        // As client is being moved of partner, mask mode needs to be updated accordingly
        setSessionStorage('maskPartnerId', partnerId);
        setSessionStorage('maskPartnerName', partnerName);
      })
      .catch(e => {
        enqueueAlertSnackbar(
          e.response.data?.description || 'Failed to move client',
          { props: { severity: 'error'} }
        );
      });
  };

  dispatch.onRefetchClientInfo = () => {
    getClientInformation();
  };

  return {
    record,
    dwba,
    loading,
    loadingDWBA,
    dispatch,
    trainingsOptions,
    productCodeOptions,
    isProductCodeOptionsLoading,
    validLink,
    openDialog,
    openReset,
    useNoteList,
    accountTypes,
    UTproductType,
    productTypes,
    campaignUsed,
    domainsRange,
    postDomainsRange,
  };
};

export default useAdminCapabilities;
