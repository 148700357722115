import { Box, Checkbox, DataGrid, Paper } from '@trustsecurenow/components-library';
import { dataProvider } from 'helpers';
import { useSelector, useDispatch } from 'react-redux';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';
import { useId } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import generateApiTableParams from 'utils/generateApiTableParams';
import Toolbar from './Toolbar';
import CustomRow from './CustomRow';
import { BSN_SET_ANY } from 'conf/constants';
import {
  ACCOUNT_OVERVIEW_VIEW_KEY,
  MODALS_INITIAL_STATE,
  SEARCH_FIELDS,
  TABLE_NAME,
  PINNED_FIELDS,
  COLUMN_SELECTOR_HIDDEN_FIELDS,
  PRODUCT_TYPES
} from './constants';
import {
  getApiFields,
  getColumnProps,
  getUpdatedFilterItems,
  getViewColumns,
  getDefaultColumnVisibilityModel,
  processRows,
  getVisibilitySettings,
  updateSelectedRowsObj
} from './helpers';
import SystemEmailsAlert from './SystemEmailsAlert';
import NoRowsOverlay from './NoRowsOverlay';
import Modals from './Modals';
import { useGridApiRef, GridRow } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { EmptyListIcon, Stack, Typography } from '@trustsecurenow/components-library';
import { isMaskModeEnabled } from 'helpers';

const mapTableRowToSelectedRow = (selectedRowsObj = {}, tableRows = []) => {
  return Object.keys(selectedRowsObj)
  .map(selectedRowId => tableRows.find(tableRow => tableRow.id === selectedRowId))
  .reduce((acc, row) => (row ? ({ ...acc, [row.id]: row }) : acc), {});
}

const ManageClientsTable = () => {
  const history = useHistory();
  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const userProfile = useSelector(state => state?.bsn?.user?.profile);
  const disabledWelcomeMessagesCount = useSelector(({ bsn }) => bsn?.user?.profile?.disabledWelcomeMessagesCount);
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const partnerId = useId({ key: 'partnerId' });
  const clientId = useId({ key: 'clientId' });
  const isMaskMode=Boolean(isMaskModeEnabled('clients'))

  // table state
  const [tableView, setTableView] = useState('');
  const [pagination, setPagination] = useState({ page: 0, pageSize: 25 });
  const [sorting, setSorting] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [], quickFilterValues: [] });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [selectedRowsObj, setSelectedRowsObj] = useState({});
  const [viewColumns, setViewColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [visibleBulkActions, setVisibleBulkActions] = useState([]);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [focusedColumn,setFocusedColumn] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const filterClientName = queryParams.get('filter');
    if(filterClientName){
      const name=JSON.parse(filterClientName).name
      setFilterModel({
        ...filterModel,
          quickFilterValues: [name]
        });
    }
  }, [history.location.search]);

  const [modalsState, setModalsState] = useState(MODALS_INITIAL_STATE);
  const setModalState = React.useCallback((modalName, state) => {
    setModalsState(s => ({
      ...s,
      [modalName]: { ...s[modalName], ...state }
    }));
  }, []);
  const resetModal = React.useCallback(modalName => {
    setModalState(modalName, MODALS_INITIAL_STATE[modalName]);
  }, []);

  const isAdmin = userRole === 'Administrator';
  const isPartnerAdmin = userRole === 'Partner Administrator';
  const isAdminOrPartnerAdmin = isAdmin || isPartnerAdmin;

  const getTableHeight = () => {
    if (tableData.disabledSystemEmailsClientsCount && isAdminOrPartnerAdmin && disabledWelcomeMessagesCount) {
      return '68vh';
    } else if (tableData.disabledSystemEmailsClientsCount || (isAdminOrPartnerAdmin && disabledWelcomeMessagesCount)) {
      if (isAdminOrPartnerAdmin && disabledWelcomeMessagesCount) {
        return '75vh';
      }
      return '77vh';
    }
    return '85vh';
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const filterClientName = queryParams.get('filter');
    if(filterClientName){
      const name=JSON.parse(filterClientName).name
      setFilterModel({
        ...filterModel,
          quickFilterValues: [name]
        });
    }
  }, [history.location.search]);

  useEffect(() => {
    const historyUpdate = history.location.pathname.includes('undefined') ? history.replace : history.push;
    if (sessionStorage.getItem('microtraining_id')) historyUpdate(`/myDashboard/microTrainings`);
    const hasAccess = accessApps?.hasOwnProperty('clients');
    if (accessApps && !hasAccess) history.push('/myDashboard/dashboard');
  }, [history, accessApps]);

  const updateTableState = useCallback((view = '') => {
    const tablePreferences = userPreferences.getMuiDataGridSettings(TABLE_NAME);
    const viewName = view || tablePreferences?.defaultView || ACCOUNT_OVERVIEW_VIEW_KEY;
    const viewPreferences = userPreferences.getMuiDataGridSettings(viewName);
    const pageSize = viewPreferences?.pageSize || 25;
    const sorting = viewPreferences?.sorting || [];
    const columnVisibilityModel = viewPreferences?.columnVisibilityModel || getDefaultColumnVisibilityModel(viewName);
    setPagination({ page: 0, pageSize });
    setSorting(sorting);
    setColumnVisibilityModel(columnVisibilityModel);
    setViewColumns(getViewColumns(viewName));
    setTableView(viewName);
  }, []);

  useEffect(() => {
    updateTableState();
  }, [updateTableState]);

  const handleChangeTableView = event => {
    const value = event.target.value;
    updateTableState(value);
  };

  useEffect(() => {
    if (!tableView) return;
    userPreferences.setMuiDataGrid({
      [TABLE_NAME]: { defaultView: tableView },
      [tableView]: {
        pageSize: pagination.pageSize,
        sorting,
        columnVisibilityModel
      }
    });
  }, [tableView, pagination.pageSize, sorting, columnVisibilityModel]);

  useEffect(() => {
    return () => {
      dispatch({
        type: BSN_SET_ANY,
        payload: {
          user: {
            profile: {
              disabledWelcomeMessagesCount: null
            }
          }
        }
      });
    };
  }, []);

  const tableParams = React.useMemo(() => {
    const params = generateApiTableParams({
      pagination,
      sorting,
      filter: { items: filterModel.items },
      search: { value: filterModel.quickFilterValues?.join(' ') || '', items: SEARCH_FIELDS }
    });
    const apiFields = getApiFields(visibleColumns);
    return {
      ...params,
      fields: JSON.stringify(apiFields)
    };
  }, [pagination, sorting, filterModel, visibleColumns]);

  const { data: tableRes, refetch: refetchTable, isFetching } = useQuery(
    [tableView, tableParams],
    () =>
      bsnclientservices.getClientsList(partnerId, {
        ...tableParams,
        pinned_ids: JSON.stringify(Object.keys(selectedRowsObj))
      }),
    {
      enabled: !!partnerId && !!tableView && !!userProfile,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: false,
      staleTime: 0,
      onSuccess: res => {
        dispatch({
          type: BSN_SET_ANY,
          payload: {
            user: {
              profile: {
                disabledWelcomeMessagesCount: res.data.disabled_welcome_message
              }
            }
          }
        });
        dispatch({
          type: BSN_SET_ANY,
          payload: {
            user: {
              profile: {
                isEBPP: res.data.is_enterprise_bpp
              }
            }
          }
        });
      }
    }
  );

  const tableData = React.useMemo(() => {
    if (!tableRes?.data) return {};
    const { data } = tableRes;
    const isEBPP = data.is_enterprise_bpp;
    const disabledSystemEmailsClientsCount = data.disabled_system_email_clients_count;
    const showSystemEmailsColumn = isEBPP && disabledSystemEmailsClientsCount;
    const rows = processRows(data.result);
    const rowCount = data.count;
    const showFooter = data.result?.length;
    return {
      disabledSystemEmailsClientsCount,
      showSystemEmailsColumn,
      rows,
      rowCount,
      showFooter,
      isEBPP
    };
  }, [tableRes]);

  // TODO: get product options using picklist api along with industry options
  const { data: productTypesOptions = [] } = useQuery(
    'product-filter-picklist',
    () => dataProvider.getList('clients', `productsType/${partnerId}`, {}),
    {
      enabled: !!partnerId,
      select: data => data?.data?.map(d => ({ label: d.name, value: d.id })),
      refetchOnWindowFocus: false
    }
  );

  const { data: industryFilterOptions = [], refetch: refetchIndustryFilterOptions } = useQuery(
    'industry-filter-picklist',
    () => bsnclientservices.getPickList({
      ...(isMaskMode ? { masked_partner_id: partnerId } : { client_id: clientId }),
       types: 'used_industries' }),
    {
      enabled: !!clientId,
      select: data => data?.data?.used_industries?.map(d => ({ label: d.name, value: d.name })),
      refetchOnWindowFocus: false
    }
  );

  const { data: industryModalOptions = [] } = useQuery(
    'industry-modal-picklist',
    () => bsnclientservices.getPickList({ client_id: clientId, types: 'industries' }),
    {
      enabled: !!clientId,
      select: data => data?.data?.industries?.map(d => ({ label: d.name, value: d.id })),
      refetchOnWindowFocus: false
    }
  );

  const toggleDownloadingReport = (isDownloading) => setIsDownloadingReport(isDownloading);

  const columnProps = React.useMemo(() => {
     const cellsProps = {
      refetchTable,
      setModalState,
      productTypesOptions,
      industryFilterOptions,
      showSystemEmailsColumn: tableData.showSystemEmailsColumn,
      isEBPP: tableData.isEBPP,
      isAdmin,
      onToggleDownloadingReport: toggleDownloadingReport,
    };
    return getColumnProps(viewColumns, cellsProps);
  }, [
    refetchTable,
    setModalState,
    productTypesOptions,
    industryFilterOptions,
    tableData.showSystemEmailsColumn,
    tableData.isEBPP,
    isAdmin,
    viewColumns,
    toggleDownloadingReport,
  ]);

  useEffect(() => {
    const settings = getVisibilitySettings(columnProps, columnVisibilityModel);
    const noChange = JSON.stringify(visibleColumns) === JSON.stringify(settings.visibleColumns);
    if (noChange) return;

    setVisibleColumns(settings.visibleColumns);
    setVisibleBulkActions(settings.visibleBulkActions);
    // remove hidden fields from filter and sort
    apiRef.current.setFilterModel({
      ...filterModel,
      items: filterModel.items?.filter(item => item.field in settings.visibleFieldsObj)
    });
    apiRef.current.setSortModel(sorting.filter(item => item.field in settings.visibleFieldsObj));
  }, [visibleColumns, columnProps, filterModel, columnVisibilityModel]);

  const handleColumnVisibilityModelChange = model => {
    // remove {[field]: true} pairs to reduce data saved in user preference
    const filteredModel = Object.keys(model).reduce((acc, field) => {
      if (model[field] === false) acc[field] = false;
      return acc;
    }, {});
    setColumnVisibilityModel(filteredModel);
  };

  const handleRowSelectionModelChange = model => {
    setSelectedRowsObj(prev => {
      const selectedRowsObj = {};
      const selectedRowsMap = apiRef.current.getSelectedRows();
      selectedRowsMap.forEach((value, key) => {
        if (value) selectedRowsObj[key] = value;
        else selectedRowsObj[key] = prev[key];
      });
      return selectedRowsObj;
    });
  };

  const updateRowSelection = React.useCallback((selectedClientIds, action) => {
    setSelectedRowsObj(prev => {
      const updated = updateSelectedRowsObj(prev, selectedClientIds, action);
      apiRef.current.setRowSelectionModel(Object.keys(updated));
      return updated;
    });
  }, []);

  const handleFilterModelChange = model => {
    const currentColumn = apiRef.current.state?.focus?.columnHeader?.field;
    if (currentColumn && currentColumn !== focusedColumn) setFocusedColumn(currentColumn);

    if (!model.items.length && focusedColumn) {
      apiRef.current.setFilterModel({
        items: [
          {
            field: focusedColumn
          }
        ],
        quickFilterValues: []
      });
    }

    const updated = { ...model, items: getUpdatedFilterItems(model.items) };

    if (JSON.stringify(updated) === JSON.stringify(filterModel)) return;
    if (updated?.items[0]?.value.length) {
      setSelectedRowsObj({});
      apiRef.current.setRowSelectionModel(Object.keys({}));
    }
    setFilterModel(updated);
    setPagination(s => ({ ...s, page: 0 }));
  };

  const handleSortModelChange = model => {
    if (JSON.stringify(model) === JSON.stringify(sorting)) return;
    setSorting(model);
    setPagination(s => ({ ...s, page: 0 }));
  };

  const getTogglableColumns = columns => {
    return columns.filter(column => !COLUMN_SELECTOR_HIDDEN_FIELDS.includes(column.field)).map(column => column.field);
  };

  return (
    <>
      <SystemEmailsAlert disabledClientsCount={tableData.disabledSystemEmailsClientsCount} />
      <Paper component={Box} height={getTableHeight()} mt={2}>
        <DataGrid
          onRowClick={({ row }) => {
            if (tableData.isEBPP && !isAdmin && row.product_type === PRODUCT_TYPES.BPP && !row.active) return;
            history.push(`/clients/dashboard/${row.id}`);
          }}
          apiRef={apiRef}
          disableColumnResize
          disableColumnReorder
          disableColumnSelector={false} // TODO: default to enabled and remove this?
          disableColumnPinning={false} // TODO: default to enabled and remove this?
          initialState={{ pinnedColumns: { left: PINNED_FIELDS } ,
          filter:{filterModel}
          }}
          slots={{
            toolbar: Toolbar,
            row: params => {
              if (tableData.isEBPP && !isAdmin && params.row.product_type === PRODUCT_TYPES.BPP && !params.row.active) {
                return <CustomRow {...params} />;
              }
              return <GridRow {...params} />;
            },
            baseCheckbox: CustomCheckbox,
            noRowsOverlay: () => (
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={3}
                padding={12}
                opacity={isFetching ? 0.5 : 1}
              >
                <EmptyListIcon sx={{ fontSize: '5.6rem' }} />
                <Typography width="33%">
                  No clients match your search or filter criteria. Try adjusting your search or filter settings.
                </Typography>
              </Stack>
            )
          }}
          slotProps={{
            toolbar: {
              tableView,
              visibleBulkActions,
              selectedRowsObj: mapTableRowToSelectedRow(selectedRowsObj, tableData.rows),
              setModalState,
              partnerId,
              isEBPP: tableData.isEBPP,
              isFetching,
              onChangeTableView: handleChangeTableView
            },
            row: {
              setModalState,
              isEBPP: tableData.isEBPP
            },
            columnsPanel: {
              getTogglableColumns
            },
            filterPanel: {
              filterFormProps: {
              },
              logicOperators: []
            }
          }}
          localeText={{
            footerRowSelected: count =>
              count >= 1 ? `${count.toLocaleString()} ${count === 1 ? 'client' : 'clients'} selected` : ``
          }}
          columns={columnProps}
          loading={isFetching || isDownloadingReport || !userProfile}
          rows={tableData.rows || []}
          rowCount={tableData.rowCount || 0}
          hideFooter={!tableData.showFooter}
          pagination
          paginationModel={pagination}
          setSortModel={sorting}
          sortModel={sorting}
          onPaginationModelChange={setPagination}
          onSortModelChange={handleSortModelChange}
          filterDebounceMs={500}
          onFilterModelChange={handleFilterModelChange}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          rowSelection={tableData.isEBPP}
          checkboxSelection={tableData.isEBPP}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          onRowSelectionModelChange={handleRowSelectionModelChange}
        />
        <Modals
          modalsState={modalsState}
          setModalState={setModalState}
          resetModal={resetModal}
          industryModalOptions={industryModalOptions}
          refetchTable={refetchTable}
          refetchIndustryFilterOptions={refetchIndustryFilterOptions}
          partnerId={partnerId}
          updateRowSelection={updateRowSelection}
        />
      </Paper>
    </>
  );
};

export default ManageClientsTable;

const CustomCheckbox = props => {
  return (
    <Checkbox
      {...props}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
};
